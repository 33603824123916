import type { FC } from 'react';
import {Link as RouterLink, useNavigate} from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
    Box,
    Breadcrumbs,
    Button, Card, CardHeader, Checkbox,
    Container, Divider, FormHelperText,
    Grid,
    Link, TextField,
    Typography
} from '@material-ui/core';
import ChevronRightIcon from '../../../res/icons/ChevronRight';
import useSettings from '../../../hooks/useSettings';
import i18n from "i18next";
import toast from 'react-hot-toast';
import {Formik} from "formik";
import * as Yup from "yup";
import {SubscriptionTypeController} from "../../../api/controller/subscriptionType.controller";
import {SubscriptionTypeCreateDTO} from "../../../model/dto/subscriptionType.dto";
import {User} from "../../../model/types/user";
import {SubscriptionType} from "../../../model/types/subscriptionType";

const SubscriptionDashboardAdd: FC = (props) => {
    const { settings } = useSettings();
    const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title> {i18n.t("aggiungi_tipologia_abbonamento")} | Micromega</title>
      </Helmet>
        <Container sx={{ m: 3 }}
                   maxWidth={settings.compact ? 'xl' : false}>
            <Grid
                container
                justifyContent="space-between"
                spacing={3}
            >
                <Grid item>
                    <Typography
                        color="textPrimary"
                        variant="h5"
                    >
                        {i18n.t("aggiungi_tipologia_abbonamento")}
                    </Typography>
                    <Breadcrumbs
                        aria-label="breadcrumb"
                        separator={<ChevronRightIcon fontSize="small" />}
                        sx={{ mt: 1 }}
                    >
                        <Link
                            color="textPrimary"
                            component={RouterLink}
                            to="/dashboard"
                            variant="subtitle2"
                        >
                            {i18n.t("dashboard")}
                        </Link>
                        <Link
                            color="textPrimary"
                            component={RouterLink}
                            to="/dashboard/subscriptions"
                            variant="subtitle2"
                        >
                            {i18n.t("tipologie_abbonamenti")}
                        </Link>

                    </Breadcrumbs>
                </Grid>
            </Grid>
            <Grid
                container
                spacing={4}
                sx={{mt: 2}}
                {...props}
            >
                <Grid
                    item
                    lg={5}
                    md={5}
                    xl={5}
                    xs={12}>
                    <Formik
                        initialValues={{
                            description: "",
                            duration: "",
                            price: "",
                            archivio: false,
                            cartaceo: false,
                            digitale: false,
                            newsletter: false,
                            spedizione: 1,
                            submit: null,
                            order: ""
                        }}
                        validationSchema={
                            Yup
                                .object()
                                .shape({
                                    description: Yup
                                        .string()
                                        .max(255)
                                        .required(i18n.t("required_description")),
                                    duration: Yup
                                        .number()
                                        .min(1)
                                        .required(i18n.t("required_duration")),
                                    price: Yup
                                        .number()
                                        .min(0.01)
                                        .required(i18n.t("required_price")),
                                    order: Yup
                                        .number()
                                        .required(i18n.t("order_required")),
                                    cartaceo: Yup
                                        .boolean()
                                        .required(i18n.t("cartaceo_required")),
                                    archivio: Yup
                                        .boolean()
                                        .required(i18n.t("archivio_required")),
                                    digitale: Yup
                                        .boolean()
                                        .required(i18n.t("digitale_required")),
                                    newsletter: Yup
                                        .boolean()
                                        .required(i18n.t("newsletter_required")),
                                    spedizione: Yup
                                        .number()
                                        .required(i18n.t("spedizione_required")),
                                })
                        }
                        onSubmit={async (values, {
                            setErrors,
                            setStatus,
                            setSubmitting
                        }): Promise<void> => {
                            try {
                                const image = "empty"; //TODO TBD
                                const resp = await SubscriptionTypeController.create(new SubscriptionTypeCreateDTO(values.description,Number(values.duration),Number(values.price),values.archivio,values.cartaceo,values.digitale,values.spedizione.toString(),values.newsletter,image,Number(values.order)))
                                if (resp) {
                                    setStatus({ success: true });
                                    setSubmitting(false);
                                    let tostMsg = i18n.t("tipologia_abbonamento_creato");
                                    toast.success(tostMsg);
                                    console.log("RESP INSERT SUBSCRIPTIONTYPE",resp);
                                    navigate('/dashboard/subscriptions/'+resp.data.id);
                                }
                            } catch (err) {
                                console.error(err);
                                setStatus({ success: false });
                                let toastMsg = i18n.t("problemi_elaborazione")
                                toast.error(toastMsg);
                                setErrors({ submit: err.stack.msg ? err.stack.msg : err.message})
                                setSubmitting(false);
                            }
                        }}>
                        {({
                              errors,
                              handleBlur,
                              handleChange,
                              handleSubmit,
                              isSubmitting,
                              touched,
                              values,
                              setFieldValue
                          }): JSX.Element => (
                            <form
                                noValidate
                                onSubmit={handleSubmit}
                                {...props}
                            >
                                <Card>
                                    <CardHeader title={i18n.t("aggiungi_tipologia_abbonamento")} />
                                    <Divider />
                                    <Grid
                                        container
                                        sx={{p: 2}}
                                        spacing={4}>
                                        <Grid
                                            item
                                            md={12}
                                            xs={12}>
                                            <TextField
                                                error={Boolean(touched.description && errors.description)}
                                                fullWidth
                                                helperText={touched.description && errors.description}
                                                label={i18n.t("descrizione")}
                                                name="description"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.description}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            md={12}
                                            xs={12}>
                                            <TextField
                                                error={Boolean(touched.duration && errors.duration)}
                                                fullWidth
                                                helperText={touched.duration && errors.duration}
                                                label={i18n.t("duration")}
                                                name="duration"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.duration}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            md={12}
                                            xs={12}>
                                            <TextField
                                                error={Boolean(touched.price && errors.price)}
                                                fullWidth
                                                helperText={touched.price && errors.price}
                                                label={i18n.t("price")}
                                                name="price"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.price}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            md={12}
                                            xs={12}>
                                        <TextField
                                            fullWidth
                                            label={i18n.t("spedizione")}
                                            name="spedizione"
                                            error={Boolean(touched.spedizione && errors.spedizione)}
                                            helperText={touched.spedizione && errors.spedizione}
                                            value={values.spedizione}
                                            select
                                            SelectProps={{ native: true }}
                                            onChange={handleChange}
                                            variant="outlined"
                                        >
                                            {SubscriptionType.getSpedizioni().map((category) => (
                                                <option
                                                    key={category.value}
                                                    value={category.value}>
                                                    {category.label}
                                                </option>
                                            ))}
                                        </TextField>
                                        </Grid>
                                        <Grid
                                            item
                                            md={12}
                                            xs={12}>
                                            <TextField
                                                error={Boolean(touched.order && errors.order)}
                                                fullWidth
                                                helperText={touched.order && errors.order}
                                                label={i18n.t("order")}
                                                name="order"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.order}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            md={12}
                                            xs={12}>
                                            <Box
                                                sx={{
                                                    alignItems: 'center',
                                                    display: 'flex',
                                                }}
                                            >
                                                <Checkbox
                                                    checked={values.digitale}
                                                    color="primary"
                                                    name="digitale"
                                                    onChange={handleChange}
                                                />
                                                <Typography
                                                    color="textSecondary"
                                                    variant="body2"
                                                >
                                                    {i18n.t("digitale")}
                                                </Typography>
                                            </Box>
                                            <Box
                                                sx={{
                                                    alignItems: 'center',
                                                    display: 'flex',
                                                }}
                                            >
                                                <Checkbox
                                                    checked={values.cartaceo}
                                                    color="primary"
                                                    name="cartaceo"
                                                    onChange={handleChange}
                                                />
                                                <Typography
                                                    color="textSecondary"
                                                    variant="body2"
                                                >
                                                    {i18n.t("cartaceo")}
                                                </Typography>
                                            </Box>
                                            <Box
                                                sx={{
                                                    alignItems: 'center',
                                                    display: 'flex',
                                                }}
                                            >
                                                <Checkbox
                                                    checked={values.newsletter}
                                                    color="primary"
                                                    name="newsletter"
                                                    onChange={handleChange}
                                                />
                                                <Typography
                                                    color="textSecondary"
                                                    variant="body2"
                                                >
                                                    {i18n.t("newsletter")}
                                                </Typography>
                                            </Box>
                                            <Box
                                                sx={{
                                                    alignItems: 'center',
                                                    display: 'flex',
                                                }}
                                            >
                                                <Checkbox
                                                    checked={values.archivio}
                                                    color="primary"
                                                    name="archivio"
                                                    onChange={handleChange}
                                                />
                                                <Typography
                                                    color="textSecondary"
                                                    variant="body2"
                                                >
                                                    {i18n.t("archivio")}
                                                </Typography>
                                            </Box>

                                        </Grid>
                                        <Grid item
                                              md={12}
                                              xs={12}>
                                            {errors.submit && (
                                                <Box sx={{ mt: 3 }}>
                                                    <FormHelperText error>
                                                        {errors.submit}
                                                    </FormHelperText>
                                                </Box>
                                            )}
                                            <Box sx={{ mt: 2 }}>
                                                <Button
                                                    color="primary"
                                                    disabled={isSubmitting}
                                                    size="large"
                                                    type="submit"
                                                    variant="contained"
                                                >
                                                    {i18n.t("aggiungi")}
                                                </Button>
                                            </Box>
                                        </Grid>

                                    </Grid>
                                </Card>

                            </form>
                        )}
                    </Formik>
                </Grid>
                <Grid
                    item
                    lg={5}
                    md={5}
                    xl={5}
                    xs={12}
                >
                </Grid>
            </Grid>
        </Container>
    </>
  );
};

export default SubscriptionDashboardAdd;
