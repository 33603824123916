import type { FC} from 'react';
import {useCallback, useEffect, useState} from 'react';
import {Link as RouterLink, useNavigate, useParams} from 'react-router-dom';
import {Helmet} from 'react-helmet-async';
import {
    Box,
    Breadcrumbs,
    Button,
    Card,
    CardHeader, Checkbox,
    Container,
    Divider, FormHelperText,
    Grid,
    Link,
    TextField,
    Typography
} from '@material-ui/core';
import ChevronRightIcon from '../../../res/icons/ChevronRight';
import useSettings from '../../../hooks/useSettings';
import i18n from "i18next";
import toast from "react-hot-toast";
import {SubscriptionType} from "../../../model/types/subscriptionType";
import {SubscriptionTypeController} from "../../../api/controller/subscriptionType.controller";
import * as Yup from "yup";
import {SubscriptionTypeDeleteDTO} from "../../../model/dto/subscriptionType.dto";
import {Formik} from "formik";
import Label from "../../../components/Label";
import ClearIcon from '@material-ui/icons/Clear';
import DoneIcon from '@material-ui/icons/Done';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
const SubscriptionDashboardEdit: FC = (props) => {
  const { settings } = useSettings();
  const [subscriptionType, setSubscriptionType] = useState<SubscriptionType | null>(null);
  const params = useParams();
  const navigate = useNavigate()
    const getStatusLabel = (status: number): JSX.Element => {
        const map = {
            0: {
                color: 'warning',
                text: i18n.t("sospeso")
            },
            1: {
                color: 'success',
                text: i18n.t("attivo")
            }
        };
        const { text, color }: any = map[status];
        return (
            <Label color={color}>
                {text}
            </Label>
        );
    };

    const get = useCallback(async () => {
    try {
      const id = params.subscriptionTypeId
      const resp = await SubscriptionTypeController.find(id)
      if (resp) {
        setSubscriptionType(resp.data);
      }
    } catch (err) {
      let testoToast = i18n.t("problemi_nel_caricamento")
      toast.error(testoToast)
      console.error(err);
    }
  }, []);
  useEffect(() => {
    get();
  }, [get]);
  if (!subscriptionType) {
    return null;
  }
  return (
    <>
      <Helmet>
        <title>{i18n.t("tipologia_abbonamento")} | Micromega</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
                <Typography
                    color="textPrimary"
                    variant="h5"
                >
                    {subscriptionType.description}
                </Typography>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/dashboard"
                  variant="subtitle2"
                >
                  {i18n.t("dashboard")}
                </Link>
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/dashboard/subscriptions"
                  variant="subtitle2"
                >
                    {i18n.t("tipologie_abbonamenti")}
                </Link>
              </Breadcrumbs>
            </Grid>
            <Grid item>
              <Box sx={{ m: -1 }}>
                <Button
                    color="primary"
                    startIcon={subscriptionType.status ? <ClearIcon fontSize="small" /> : <DoneIcon fontSize="small" />}
                    sx={{ m: 1 }}
                    onClick={async () => {
                          subscriptionType.status = subscriptionType.status === 1 ? 0 : 1
                      try{
                        const resp = await SubscriptionTypeController.update(subscriptionType)
                        setSubscriptionType(resp.data)
                      }catch(ex){
                        let testoToast = i18n.t("operazione_non_riuscita_riprova")
                        toast.error(testoToast)
                      }
                    }}
                    variant="contained">{subscriptionType.status ? i18n.t("blocca") : i18n.t("attiva")}</Button>
                <Button
                    color="primary"
                    startIcon={<RemoveCircleOutlineIcon fontSize="small" />}
                    sx={{ m: 1 }}
                    onClick={async () => {
                      try{
                          const resp = await SubscriptionTypeController.delete(new SubscriptionTypeDeleteDTO(subscriptionType.id))
                          navigate("/dashboard/subscriptions")
                      }catch(ex){
                        let testoToast = i18n.t("operazione_non_riuscita_riprova")
                        toast.error(testoToast)
                      }
                    }}
                    variant="contained">{i18n.t("elimina")}</Button>
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
              <Grid
                  container
                  spacing={3}
                  {...props}
              >
                  <Grid
                      item
                      lg={6}
                      md={6}
                      xl={6}
                      xs={12}>
                      <Formik
                          initialValues={{
                              description: subscriptionType.description,
                              duration: subscriptionType.duration,
                              price: subscriptionType.price,
                              archivio: subscriptionType.archivio === 1,
                              cartaceo: subscriptionType.cartaceo === 1,
                              digitale: subscriptionType.digitale === 1,
                              newsletter: subscriptionType.newsletter === 1,
                              spedizione: subscriptionType.spedizione,
                              order: subscriptionType.order,
                              submit: null
                          }}
                          validationSchema={
                              Yup
                                  .object()
                                  .shape({
                                      description: Yup
                                          .string()
                                          .max(255)
                                          .required(i18n.t("required_description")),
                                      duration: Yup
                                          .number()
                                          .min(1)
                                          .required(i18n.t("required_duration")),
                                      price: Yup
                                          .number()
                                          .min(0.01)
                                          .required(i18n.t("required_price")),
                                      cartaceo: Yup
                                          .boolean()
                                          .required(i18n.t("cartaceo_required")),
                                      order: Yup
                                          .number()
                                          .required(i18n.t("order_required")),
                                      archivio: Yup
                                          .boolean()
                                          .required(i18n.t("archivio_required")),
                                      digitale: Yup
                                          .boolean()
                                          .required(i18n.t("digitale_required")),
                                      newsletter: Yup
                                          .boolean()
                                          .required(i18n.t("newsletter_required")),
                                      spedizione: Yup
                                          .number()
                                          .required(i18n.t("spedizione_required")),
                                  })
                          }
                          onSubmit={async (values, {
                              setErrors,
                              setStatus,
                              setSubmitting
                          }): Promise<void> => {
                              try {
                                  subscriptionType.description = values.description
                                  subscriptionType.duration = values.duration
                                  subscriptionType.digitale = values.digitale ? 1 : 0
                                  subscriptionType.cartaceo = values.cartaceo ? 1 : 0
                                  subscriptionType.archivio = values.archivio ? 1 : 0
                                  subscriptionType.newsletter = values.newsletter ? 1 : 0
                                  subscriptionType.price = values.price
                                  subscriptionType.spedizione = values.spedizione
                                  subscriptionType.order = values.order

                                  const resp = await SubscriptionTypeController.update(subscriptionType)
                                  if (resp) {
                                      setStatus({ success: true });
                                      setSubmitting(false);
                                      let tostMsg = i18n.t("aggiornato");
                                      toast.success(tostMsg);
                                      navigate('/dashboard/subscriptions/'+resp.data.id);
                                  }
                              } catch (err) {
                                  console.error(err);
                                  setStatus({ success: false });
                                  let toastMsg = i18n.t("problemi_elaborazione")
                                  toast.error(toastMsg);
                                  setErrors({ submit: err.stack.msg ? err.stack.msg : err.message})
                                  setSubmitting(false);
                              }
                          }}>
                          {({
                                errors,
                                handleBlur,
                                handleChange,
                                handleSubmit,
                                isSubmitting,
                                touched,
                                values,
                                setFieldValue
                            }): JSX.Element => (
                              <form
                                  noValidate
                                  onSubmit={handleSubmit}
                                  {...props}
                              >
                                  <Card>
                                      <CardHeader title={i18n.t("modifica_tipologia_abbonamento")} />
                                      <Divider />
                                      <Grid
                                          container
                                          sx={{mt: 0.5, p: 2}}
                                          spacing={3}>
                                          <Grid
                                              item
                                              md={12}
                                              xs={12}>
                                              <TextField
                                                  error={Boolean(touched.description && errors.description)}
                                                  fullWidth
                                                  helperText={touched.description && errors.description}
                                                  label={i18n.t("descrizione")}
                                                  name="description"
                                                  onBlur={handleBlur}
                                                  onChange={handleChange}
                                                  value={values.description}
                                                  variant="outlined"
                                              />
                                          </Grid>
                                          <Grid
                                              item
                                              md={12}
                                              xs={12}>
                                              <TextField
                                                  error={Boolean(touched.duration && errors.duration)}
                                                  fullWidth
                                                  helperText={touched.duration && errors.duration}
                                                  label={i18n.t("duration")}
                                                  name="duration"
                                                  onBlur={handleBlur}
                                                  onChange={handleChange}
                                                  value={values.duration}
                                                  variant="outlined"
                                              />
                                          </Grid>
                                          <Grid
                                              item
                                              md={12}
                                              xs={12}>
                                              <TextField
                                                  error={Boolean(touched.price && errors.price)}
                                                  fullWidth
                                                  helperText={touched.price && errors.price}
                                                  label={i18n.t("price")}
                                                  name="price"
                                                  onBlur={handleBlur}
                                                  onChange={handleChange}
                                                  value={values.price}
                                                  variant="outlined"
                                              />
                                          </Grid>
                                          <Grid
                                              item
                                              md={12}
                                              xs={12}>
                                              <TextField
                                                  fullWidth
                                                  label={i18n.t("spedizione")}
                                                  name="spedizione"
                                                  error={Boolean(touched.spedizione && errors.spedizione)}
                                                  helperText={touched.spedizione && errors.spedizione}
                                                  value={values.spedizione}
                                                  select
                                                  SelectProps={{ native: true }}
                                                  onChange={handleChange}
                                                  variant="outlined"
                                              >
                                                  {SubscriptionType.getSpedizioni().map((category) => (
                                                      <option
                                                          key={category.value}
                                                          value={category.value}>
                                                          {category.label}
                                                      </option>
                                                  ))}
                                              </TextField>
                                          </Grid>
                                          <Grid
                                              item
                                              md={12}
                                              xs={12}>
                                              <TextField
                                                  error={Boolean(touched.order && errors.order)}
                                                  fullWidth
                                                  helperText={touched.order && errors.order}
                                                  label={i18n.t("order")}
                                                  name="order"
                                                  onBlur={handleBlur}
                                                  onChange={handleChange}
                                                  value={values.order}
                                                  variant="outlined"
                                              />
                                          </Grid>
                                          <Grid
                                              item
                                              md={12}
                                              xs={12}>
                                              <Box
                                                  sx={{
                                                      alignItems: 'center',
                                                      display: 'flex',
                                                      p: 1
                                                  }}>
                                                  <Typography
                                                      color="textSecondary"
                                                      variant="body2">{i18n.t("stato_abbonamento")+": \t"}</Typography>
                                                  <Box sx={{ml: 1}}>{getStatusLabel(subscriptionType.status)}</Box>
                                              </Box>

                                              <Box
                                                  sx={{
                                                      alignItems: 'center',
                                                      display: 'flex',
                                                  }}
                                              >
                                                  <Checkbox
                                                      checked={values.digitale}
                                                      color="primary"
                                                      name="digitale"
                                                      onChange={handleChange}
                                                  />
                                                  <Typography
                                                      color="textSecondary"
                                                      variant="body2"
                                                  >
                                                      {i18n.t("digitale")}
                                                  </Typography>
                                              </Box>
                                              <Box
                                                  sx={{
                                                      alignItems: 'center',
                                                      display: 'flex',
                                                  }}
                                              >
                                                  <Checkbox
                                                      checked={values.cartaceo}
                                                      color="primary"
                                                      name="cartaceo"
                                                      onChange={handleChange}
                                                  />
                                                  <Typography
                                                      color="textSecondary"
                                                      variant="body2"
                                                  >
                                                      {i18n.t("cartaceo")}
                                                  </Typography>
                                              </Box>
                                              <Box
                                                  sx={{
                                                      alignItems: 'center',
                                                      display: 'flex',
                                                  }}
                                              >
                                                  <Checkbox
                                                      checked={values.newsletter}
                                                      color="primary"
                                                      name="newsletter"
                                                      onChange={handleChange}
                                                  />
                                                  <Typography
                                                      color="textSecondary"
                                                      variant="body2"
                                                  >
                                                      {i18n.t("newsletter")}
                                                  </Typography>
                                              </Box>
                                              <Box
                                                  sx={{
                                                      alignItems: 'center',
                                                      display: 'flex',
                                                  }}
                                              >
                                                  <Checkbox
                                                      checked={values.archivio}
                                                      color="primary"
                                                      name="archivio"
                                                      onChange={handleChange}
                                                  />
                                                  <Typography
                                                      color="textSecondary"
                                                      variant="body2"
                                                  >
                                                      {i18n.t("archivio")}
                                                  </Typography>
                                              </Box>
                                          </Grid>
                                          <Grid item
                                                md={12}
                                                xs={12}>
                                              {errors.submit && (
                                                  <Box sx={{ mt: 3 }}>
                                                      <FormHelperText error>
                                                          {errors.submit}
                                                      </FormHelperText>
                                                  </Box>
                                              )}
                                              <Box>
                                                  <Button
                                                      color="primary"
                                                      disabled={isSubmitting}
                                                      size="large"
                                                      type="submit"
                                                      variant="contained"
                                                  >
                                                      {i18n.t("aggiorna")}
                                                  </Button>
                                              </Box>
                                          </Grid>

                                      </Grid>
                                  </Card>

                              </form>
                          )}
                      </Formik>
                  </Grid>
                  <Grid
                      item
                      lg={6}
                      md={6}
                      xl={6}
                      xs={12}
                  >

                  </Grid>

              </Grid>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default SubscriptionDashboardEdit;
