import type {FC, ReactNode} from 'react';
import {useState} from 'react';
import {Navigate, useLocation} from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../../hooks/useAuth';
import Login from '../../pages/authentication/Login';
import {UserTypeDesc} from "../../model/types/user";
import AdminRequired from "../../pages/guard/AdminRequired";

interface AuthGuardProps {
  children: ReactNode;
}

const AdminGuard: FC<AuthGuardProps> = (props) => {
  const { children } = props;
  const auth = useAuth();
  const location = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);

  if (!auth.isAuthenticated) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }

    return <Login />;
  }else if(auth.user.userType.desc !== UserTypeDesc.Admin){
    return <AdminRequired />
  }
  // This is done so that in case the route changes by any chance through other
  // means between the moment of request and the render we navigate to the initially
  // requested route.
  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
};

AdminGuard.propTypes = {
  children: PropTypes.node
};

export default AdminGuard;
