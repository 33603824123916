import React, { useCallback, useEffect, useState } from 'react';
import type { FC } from 'react';
import {Link as RouterLink, useNavigate} from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
    Alert,
    Box,
    Breadcrumbs,
    Button, Card,
    Container,
    Grid,
    Link, Stack,
    Typography
} from '@material-ui/core';
import useSettings from '../../../hooks/useSettings';
import ChevronRightIcon from '../../../res/icons/ChevronRight';
import i18n from "i18next";
import {SubscriptionTypeSpedizione} from "../../../model/types/subscriptionType";
import {useTheme} from "@material-ui/core/styles";
import PlusIcon from "../../../res/icons/Plus";
import useAuth from "../../../hooks/useAuth";
import {User} from "../../../model/types/user";
import SubscriptionList from "../../../components/pricing/SubscriptionList";

const SubscriptionDashboardList: FC = () => {
  const theme = useTheme();
  const {user} = useAuth()
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const { settings } = useSettings();

  return (
    <>
      <Helmet>
        <title>{User.isAdmin(user) ? i18n.t("tipologie_abbonamenti") : i18n.t("negozio")} | Micromega</title>
      </Helmet>

        <Box
            sx={{
                backgroundColor: 'background.default',
                minHeight: '100%',
                py: 8
            }}
        >
            <Container maxWidth={settings.compact ? 'xl' : false}>
                <Grid
                    container
                    justifyContent="space-between"
                    spacing={3}
                >
                    <Grid item>
                        <Typography
                            color="textPrimary"
                            variant="h5"
                        >
                            {User.isAdmin(user) ? i18n.t("tipologie_abbonamenti") : i18n.t("negozio")}
                        </Typography>
                        <Breadcrumbs
                            aria-label="breadcrumb"
                            separator={<ChevronRightIcon fontSize="small" />}
                            sx={{ mt: 1 }}
                        >
                            <Link
                                color="textPrimary"
                                component={RouterLink}
                                to="/dashboard"
                                variant="subtitle2"
                            >
                                {i18n.t("dashboard")}
                            </Link>
                            <Typography
                                color="textSecondary"
                                variant="subtitle2"
                            >
                                {User.isAdmin(user) ? i18n.t("tipologie_abbonamenti") : i18n.t("negozio")}
                            </Typography>
                        </Breadcrumbs>

                    </Grid>
                    {
                        User.isAdmin(user) ? (
                            <Grid item>
                                <Box sx={{ m: -1 }}>
                                    <Button
                                        color="primary"
                                        startIcon={<PlusIcon fontSize="small" />}
                                        sx={{ m: 1 }}
                                        onClick={()=>{navigate('/dashboard/subscriptions/add');}}
                                        variant="contained"
                                    >
                                        {i18n.t("aggiungi_abbonamento")}
                                    </Button>
                                </Box>
                            </Grid>
                        ) : null
                    }
                </Grid>
                {
                    /*
                     <Box sx={{
                    pt: 6
                }}>
                    <Container
                        maxWidth="md"
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'column',
                            px: {
                                md: '130px !important'
                            }
                        }}
                    >

                        <Alert sx={{m: 1}} variant="filled" severity="warning">
                            La sottoscrizione degli abbonamenti è momentaneamente sospesa.
                            Le ragioni sono riportate nell'appello che puoi leggere cliccando <a href={"https://www.micromega.net/micromega-muore/"} > qui.</a>
                            <br></br>Grazie per il sostegno che potrai darci.
                            <br></br>
                            <br></br>Rimane attiva la possibilità di acquistare le singole copie della rivista, in formato cartaceo e digitale.
                        </Alert>
                    </Container>
                </Box>
                     */
                }
                <Box
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        pt: 3,
                        px: 3
                    }}
                >
                    {
                        /*
                         <Typography
                        color="textPrimary"
                        variant="h3"
                    >
                        {i18n.t("prodotti").toUpperCase()}
                    </Typography>
                    <SubscriptionList singola={true} card={true}/>
                    <Typography
                        color="textPrimary"
                        variant="h3"
                    >
                        {i18n.t("abbonamenti_italia")}
                    </Typography>
                    <SubscriptionList singola={false} card={true} filterSpedizione={SubscriptionTypeSpedizione.Nazionali} newsletter={false}/>
                    <Typography
                        color="textPrimary"
                        variant="h5"
                    >
                        {i18n.t("testo_abbonamenti_extra_italia")}
                    </Typography>
                        */
                    }
                   
                </Box>
            </Container>
        </Box>
    </>
  );
};

export default SubscriptionDashboardList;
