import i18n from "i18next";

export class SubscriptionType {
  id: number;
  duration: number;
  description: string;
  status: number;
  price: number;
  createdAt: string;
  updatedAt: string;
  archivio: number;
  cartaceo: number;
  digitale: number;
  newsletter: number;
  spedizione: SubscriptionTypeSpedizione;
  image: string;
  order: number;

  public static getSpedizioni(): Array<{value: string,label: string}>{
    return [
      {
        value: '0',
        label: i18n.t("nessuna_spedizione")
      },
      {
        value: '1',
        label: i18n.t("spedizioni_nazionali")
      },
      {
        value: '2',
        label: i18n.t("spedizioni_europee")
      },
      {
        value: '3',
        label: i18n.t("spedizioni_internazionali")
      },
    ]
  }
}
export enum SubscriptionTypeSpedizione{
  Nazionali = 1,
  Europee = 2,
  Mondo = 3
}
export function getFeatures(subscriptionType: SubscriptionType): Array<string>{
  let array = new Array<string>()
  if(subscriptionType.duration == 1){
    let testo = "1 " + i18n.t("copia");
    if(subscriptionType.digitale && subscriptionType.cartaceo){
      testo += " "+i18n.t("digitale_cartaceo_sing").toLowerCase();
    }else if(subscriptionType.digitale){
      testo += " "+i18n.t("digitale").toLowerCase();
    }else if(subscriptionType.cartaceo){
      testo += " "+i18n.t("cartacea").toLowerCase();
    }
    array.push(testo)
  }else if(subscriptionType.duration > 1){
    let testo = subscriptionType.duration+" " + i18n.t("copie");
    if(subscriptionType.digitale && subscriptionType.cartaceo){
      testo += " "+i18n.t("digitale_cartaceo_plur").toLowerCase();
    }else if(subscriptionType.digitale){
      testo += " "+i18n.t("digitali").toLowerCase();
    }else if(subscriptionType.cartaceo){
      testo += " "+i18n.t("cartacee").toLowerCase();
    }
    array.push(testo)
  }

  if(subscriptionType.newsletter){
    array.push(i18n.t("newsletter_num"))
  }
  if(subscriptionType.archivio){
    array.push(i18n.t("archivio_data"))
  }
  /*if(subscriptionType.spedizione){
    if(subscriptionType.spedizione === SubscriptionTypeSpedizione.Nazionali){
      array.push(i18n.t("spedizioni_nazionali"))
    }else if(subscriptionType.spedizione === SubscriptionTypeSpedizione.Europee){
      array.push(i18n.t("spedizioni_europee"))
    }else if(subscriptionType.spedizione === SubscriptionTypeSpedizione.Internazionali){
      array.push(i18n.t("spedizioni_internazionali"))
    }
  }*/
  return array
}

