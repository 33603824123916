import AWS from 'aws-sdk';

export class S3Controller{
    static digitalOceanSpaces = "https://micromega.fra1.digitaloceanspaces.com"
    static bucketName = "micromega"
    static spacesEndpoint = new AWS.Endpoint('fra1.digitaloceanspaces.com');
    static s3 = new AWS.S3({
        endpoint: S3Controller.spacesEndpoint,
        accessKeyId: "MJMZYJJV2VPROQ6EM6ZT",
        secretAccessKey: "cOsfIK6bbTEXNHsdygknB8kxBfrC4dPQbCQFH7jhZ+4"
    });
    static bookFolder = "Books"
    public static async uploadImageBook(file: File,uuid: string): Promise<string> {
        const ext = file.name.substr(file.name.lastIndexOf('.') + 1);
        const fileNameFinal = this.bookFolder + "/" + uuid +"/"+uuid+"."+ext
        const params = { Body: file, Bucket: `${this.bucketName}`, Key: fileNameFinal};
        return new Promise((resolve, reject) => {S3Controller.s3.putObject(params)
            .on('build', request => {
                request.httpRequest.headers.Host = `${this.digitalOceanSpaces}`;
                request.httpRequest.headers['Content-Length'] = String(file.size);
                request.httpRequest.headers['Content-Type'] = file.type;
                request.httpRequest.headers['x-amz-acl'] = 'public-read';
                request.httpRequest.headers['Access-Control-Allow-Origin'] = '*'
            })
            .send((err) => {
                if (err) {
                    reject(err)
                } else {
                    resolve(fileNameFinal)
                }
            })
        });
    }
    public static async uploadAttachmentBook(file: File,uuid: string): Promise<string> {
        const ext = file.name.substr(file.name.lastIndexOf('.') + 1);
        const fileNameFinal = this.bookFolder + "/" + uuid +"/"+uuid+"."+ext
        const params = { Body: file, Bucket: `${this.bucketName}`, Key: fileNameFinal};
        return new Promise((resolve, reject) => {S3Controller.s3.putObject(params)
            .on('build', request => {
                request.httpRequest.headers.Host = `${this.digitalOceanSpaces}`;
                request.httpRequest.headers['Content-Length'] = String(file.size);
                request.httpRequest.headers['Content-Type'] = file.type;
                request.httpRequest.headers['x-amz-acl'] = 'private';
                request.httpRequest.headers['Access-Control-Allow-Origin'] = '*'
            })
            .send((err) => {
                if (err) {
                    reject(err)
                } else {
                    resolve(fileNameFinal)
                }
            })
        });
    }
    public static async getSignedUrl(objectPath: string): Promise<string>{
        const params = {Bucket: `${this.bucketName}`, Key: objectPath, Expires: 60};
        return await S3Controller.s3.getSignedUrlPromise('getObject', params)
    }
}