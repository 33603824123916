import type { FC } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  FormHelperText,
  TextField
} from '@material-ui/core';
import useAuth from '../../../hooks/useAuth';
import useMounted from '../../../hooks/useMounted';
import i18n from "i18next";
import {useLocation, useNavigate} from "react-router-dom";
import {Location} from "history";
import {SubscriptionType} from "../../../model/types/subscriptionType";
interface LocationState {
    subscriptionType?: SubscriptionType;
}

const LoginEmail: FC = (props) => {
  const mounted = useMounted();
  const { login } = useAuth() as any;
  const location = useLocation() as Location<LocationState>;
  const navigate = useNavigate();

  return (
    <Formik
      initialValues={{
          email: '',
          password: '',
          submit: null
      }}
      validationSchema={
        Yup
          .object()
          .shape({
            email: Yup
              .string()
              .email(i18n.t("valid_email"))
              .max(255)
              .required(i18n.t("required_email")),
            password: Yup
              .string()
              .max(255)
              .required(i18n.t("required_password"))
          })
      }
      onSubmit={async (values, {
        setErrors,
        setStatus,
        setSubmitting
      }): Promise<void> => {
        try {
          await login(values.email, values.password);
          if (mounted.current) {
            setStatus({ success: true });
            setSubmitting(false);
              if(location?.state && location?.state.subscriptionType){
                  navigate('/checkout', {state: {subscriptionType: location?.state.subscriptionType}});
              }
          }
        } catch (err) {
          console.error(err);
          if (mounted.current) {
            setStatus({ success: false });
            setErrors({ submit: err.stack.msg ? err.stack.msg : err.message });
            setSubmitting(false);
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }): JSX.Element => (
        <form
          noValidate
          onSubmit={handleSubmit}
          {...props}
        >
          <TextField
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            label={i18n.t("email")}
            margin="normal"
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            type="email"
            value={values.email}
            variant="outlined"
          />
          <TextField
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            label={i18n.t("password")}
            margin="normal"
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
          />
          {errors.submit && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>
                {errors.submit}
              </FormHelperText>
            </Box>
          )}
          <Box sx={{ mt: 2 }}>
            <Button
              color="primary"
              disabled={isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
                {i18n.t("accedi")}
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default LoginEmail;
