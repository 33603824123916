import { Suspense, lazy } from 'react';
import type { PartialRouteObject } from 'react-router';
import AuthGuard from './components/guards/AuthGuard';
import DashboardLayout from './components/dashboard/DashboardLayout';
import GuestGuard from './components/guards/GuestGuard';
import LoadingScreen from './components/LoadingScreen';
import MainLayout from './components/MainLayout';
import FAQ from "./pages/FAQ";
import SubscriptionDashboardList from "./pages/dashboard/Subscription/SubscriptionDashboardList";
import CustomerAdd from "./pages/dashboard/Customer/CustomerAdd";
import SubscriptionDashboardAdd from "./pages/dashboard/Subscription/SubscriptionDashboardAdd";
import SubscriptionDashboardEdit from "./pages/dashboard/Subscription/SubscriptionDashboardEdit";
import SubscriptionPurchasedList from "./pages/dashboard/SubscriptionPurchased/SubscriptionPurchasedList";
import SubscriptionPurchasedDetail from "./pages/dashboard/SubscriptionPurchased/SubscriptionPurchasedDetail";
import CouponDetail from "./pages/dashboard/Coupon/CouponDetail";
import AdminGuard from "./components/guards/AdminGuard";
import CheckoutMobile from "./pages/CheckoutMobile";
import SubscriptionDashboardDetail from "./pages/dashboard/Subscription/SubscriptionDashboardDetail";

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// Authentication pages

const Login = Loadable(lazy(() => import('./pages/authentication/Login')));
const PasswordRecovery = Loadable(lazy(() => import('./pages/authentication/PasswordRecovery')));
const PasswordReset = Loadable(lazy(() => import('./pages/authentication/PasswordReset')));
const Register = Loadable(lazy(() => import('./pages/authentication/Register')));

// Dashboard pages

const DashboardHome = Loadable(lazy(() => import('./pages/dashboard/DashboardHome')));
const Profile = Loadable(lazy(() => import('./pages/dashboard/Profile')));
const CustomerDetails = Loadable(lazy(() => import('./pages/dashboard/Customer/CustomerDetails')));
const CustomerList = Loadable(lazy(() => import('./pages/dashboard/Customer/CustomerList')));
const OrderDetail = Loadable(lazy(() => import('./pages/dashboard/Order/OrderDetail')));
const OrderList = Loadable(lazy(() => import('./pages/dashboard/Order/OrderList')));
const ProductList = Loadable(lazy(() => import('./pages/dashboard/Product/ProductList')));
const ProductAdd = Loadable(lazy(() => import('./pages/dashboard/Product/ProductAdd')));
const ProductEdit = Loadable(lazy(() => import('./pages/dashboard/Product/ProductEdit')));
const ProductDetail = Loadable(lazy(() => import('./pages/dashboard/Product/ProductDetail')));
const CouponList = Loadable(lazy(() => import('./pages/dashboard/Coupon/CouponList')));
const CouponAdd = Loadable(lazy(() => import('./pages/dashboard/Coupon/CouponAdd')));
const Viewer = Loadable(lazy(() => import('./pages/dashboard/Viewer/MMViewer')));

// Error pages

const AuthorizationRequired = Loadable(lazy(() => import('./pages/guard/AuthorizationRequired')));
const NotFound = Loadable(lazy(() => import('./pages/guard/NotFound')));
const ServerError = Loadable(lazy(() => import('./pages/guard/ServerError')));

// Other pages
const CheckoutPurchased = Loadable(lazy(() => import('./pages/CheckoutPurchased')));
const Checkout = Loadable(lazy(() => import('./pages/Checkout')));
const Contact = Loadable(lazy(() => import('./pages/Contact')));
const Home = Loadable(lazy(() => import('./pages/Home')));
const Subscription = Loadable(lazy(() => import('./components/pricing/SubscriptionList')));

const routes: PartialRouteObject[] = [
  {
    path: 'authentication',
    children: [
      {
        path: 'login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        )
      },
      {
        path: 'password-recovery',
        element: <PasswordRecovery />
      },
      {
        path: 'password-reset',
        element: <PasswordReset />
      },
      {
        path: 'register',
        element: (
          <GuestGuard>
            <Register />
          </GuestGuard>
        )
      }
    ]
  },
  {
    path: 'contact',
    element: <Contact />
  },
  {
    path: 'faq',
    element: <FAQ />
  },
  {
    path: 'dashboard',
    element: (
        <DashboardLayout />
    ),
    children: [
      {
        path: '/',
        element: <DashboardHome />
      },
      {
        path: 'profile',
        element:
            ( <AuthGuard>
              <Profile />
            </AuthGuard>)
      },
      {
        path: 'customers',
        children: [
          {
            path: '/',
            element: <AdminGuard><CustomerList /></AdminGuard>
          },
          {
            path: ':customerId',
            element: <AdminGuard><CustomerDetails /></AdminGuard>
          },
          {
            path: '/add',
            element: <AdminGuard><CustomerAdd /></AdminGuard>
          }
        ]
      },
      {
        path: 'orders',
        children: [
          {
            path: '/',
            element: <AuthGuard><OrderList /></AuthGuard>
          },
          {
            path: ':orderId',
            element: <AuthGuard><OrderDetail /></AuthGuard>
          }
        ]
      },
      {
        path: 'coupons',
        children: [
          {
            path: '/',
            element: <AdminGuard><CouponList /></AdminGuard>
          },
          {
            path: ':couponId',
            element: <AdminGuard><CouponDetail /></AdminGuard>
          },
          {
            path: '/add',
            element: <AdminGuard><CouponAdd /></AdminGuard>
          }
        ]
      },
      {
        path: 'subscriptions',
        children: [
          {
            path: '/',
            element: <SubscriptionDashboardList />
          },
          {
            path: '/:subscriptionTypeId',
            element: <SubscriptionDashboardDetail/>
          },
          {
            path: '/edit/:subscriptionTypeId',
            element: <AdminGuard><SubscriptionDashboardEdit/></AdminGuard>
          },
          {
            path: '/add',
            element: <AdminGuard><SubscriptionDashboardAdd /></AdminGuard>
          },{
            path: '/purchased',
            element: <AdminGuard><SubscriptionPurchasedList /></AdminGuard>
          },
          {
            path: '/purchased/:idPurchased',
            element: <AdminGuard><SubscriptionPurchasedDetail /></AdminGuard>
          }
        ]
      },
      {
        path: 'products',
        children: [
          {
            path: '/',
            element: <ProductList />
          },
          {
            path: ':productId',
            element: <ProductDetail />
          },
          {
            path: '/edit/:productId',
            element: <AdminGuard><ProductEdit /></AdminGuard>
          },
          {
            path: '/add',
            element: <AdminGuard><ProductAdd /></AdminGuard>
          }
        ]
      },
      {
        path: 'viewer',
        children: [
          {
            path: '/',
            element: <AuthGuard><Viewer /></AuthGuard>
          }
        ]
      }
    ]
  },
  {
    path: '*',
    element: <MainLayout />,
    children: [
      {
        path: '/',
        element: <Home />
      },
      {
        path: 'checkout',
        element:(<AuthGuard><Checkout /></AuthGuard>)
      },
      {
        path: 'checkout/purchased',
        element:(<AuthGuard><CheckoutPurchased /></AuthGuard>)
      },
      {
        path: 'checkoutMobile',
        element:(<CheckoutMobile />)
      },
      {
        path: '401',
        element: <AuthorizationRequired />
      },
      {
        path: '404',
        element: <NotFound />
      },
      {
        path: '500',
        element: <ServerError />
      },
      {
        path: '*',
        element: <NotFound />
      }
    ]
  }
];

export default routes;
