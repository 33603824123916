import type { FC } from 'react';
import type { Theme } from '@material-ui/core';
import type { SxProps } from '@material-ui/system';
import logoWhite from '../res/img/micromega_icona_white.svg';
import {Box} from "@material-ui/core";

interface LogoProps {
  sx?: SxProps<Theme>;
}
const LogoWhite: FC<LogoProps> = (props) => (
  <div>
      <Box
          component="img"
          src={logoWhite}
          sx={{
              height: 52,
              width: 52
          }}
      />
  </div>
);

export default LogoWhite;
