import type {FC} from 'react';
import {useCallback, useEffect, useState} from 'react';
import {Link as RouterLink, useNavigate, useParams} from 'react-router-dom';
import {Helmet} from 'react-helmet-async';
import Markdown from 'react-markdown/with-html';
import {Box, Breadcrumbs, Button, Container, Divider, Grid, Link, Typography} from '@material-ui/core';
import {experimentalStyled} from '@material-ui/core/styles';
import PencilAltIcon from '../../../res/icons/PencilAlt';
import useMounted from "../../../hooks/useMounted";
import {Product, ProductStatus} from "../../../model/types/product";
import {ProductController} from "../../../api/controller/product.controller";
import i18n from "i18next";
import toast from "react-hot-toast";
import ChevronRightIcon from "../../../res/icons/ChevronRight";
import useAuth from "../../../hooks/useAuth";
import {User} from "../../../model/types/user";
import {S3Controller} from "../../../api/controller/s3.controller";
import ImportContactsIcon from "@material-ui/icons/ImportContacts";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import DoneIcon from '@material-ui/icons/Done';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import {Order} from "../../../model/types/order";
import numeral from 'numeral';
import useSettings from "../../../hooks/useSettings";
import {getFeatures, SubscriptionType, SubscriptionTypeSpedizione} from "../../../model/types/subscriptionType";
import {SubscriptionTypeController} from "../../../api/controller/subscriptionType.controller";
import CheckIcon from "../../../res/icons/Check";
import SubscriptionList from "../../../components/pricing/SubscriptionList";

const MarkdownWrapper = experimentalStyled('div')(
    ({ theme }) => (
        {
            color: theme.palette.text.primary,
            fontFamily: theme.typography.fontFamily,
            '& h2': {
                fontSize: theme.typography.h2.fontSize,
                fontWeight: theme.typography.fontWeightBold,
                lineHeight: theme.typography.h2.lineHeight,
                marginBottom: theme.spacing(3)
            },
            '& h3': {
                fontSize: theme.typography.h3.fontSize,
                fontWeight: theme.typography.fontWeightBold,
                lineHeight: theme.typography.h3.lineHeight,
                marginBottom: theme.spacing(3)
            },
            '& p': {
                fontSize: theme.typography.body1.fontSize,
                lineHeight: theme.typography.body1.lineHeight,
                marginBottom: theme.spacing(2)
            },
            '& li': {
                fontSize: theme.typography.body1.fontSize,
                lineHeight: theme.typography.body1.lineHeight,
                marginBottom: theme.spacing(1)
            }
        }
    )
);

const SubscriptionDashboardDetail: FC = () => {
    const mounted = useMounted();
    const [subscription, setSubscription] = useState<SubscriptionType | null>(null);
    const params = useParams();
    const {user} = useAuth();
    const { settings } = useSettings();
    const navigate = useNavigate()
    const getSubscriptionType = useCallback(async () => {
        try {
            const data = await SubscriptionTypeController.find(params.subscriptionTypeId)
            setSubscription(data.data);
        } catch (err) {
            let testoToast = i18n.t("problemi_nel_caricamento")
            toast.error(testoToast)
            console.error(err);
        }
    }, []);

    useEffect(() => {
        getSubscriptionType();
    }, [getSubscriptionType]);

    if (!subscription) {
        return null;
    }
    const isNewsletter = subscription.newsletter === 1 && subscription.archivio === 0 && subscription.cartaceo === 0 && subscription.digitale === 0

    return (
        <>
            <Helmet>
                <title>{subscription ? subscription.description : i18n.t("abbonamento")} | Micromega</title>
            </Helmet>
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    minHeight: '100%',
                    py: 8
                }}
            >
                <Container maxWidth={settings.compact ? 'xl' : false}>

                    <Grid
                        container
                        justifyContent="space-between"
                        spacing={3}
                    >
                        <Grid item>
                            <Typography
                                color="textPrimary"
                                variant="h5"
                            >
                                {subscription.description}
                            </Typography>
                            <Breadcrumbs
                                aria-label="breadcrumb"
                                separator={<ChevronRightIcon fontSize="small" />}
                                sx={{ mt: 2 }}
                            >
                                <Link
                                    color="textPrimary"
                                    component={RouterLink}
                                    to="/dashboard"
                                    variant="subtitle2"
                                >
                                    {i18n.t("dashboard")}
                                </Link>
                                <Link
                                    color="textPrimary"
                                    component={RouterLink}
                                    to="/dashboard/subscriptions"
                                    variant="subtitle2"
                                >
                                    {i18n.t("negozio")}
                                </Link>
                            </Breadcrumbs>
                        </Grid>
                        <Grid item>
                            <Box sx={{ m: -1 }}>
                                {User.isAdmin(user) ? (
                                    <Button
                                        color="primary"
                                        sx={{mr: 1}}
                                        startIcon={<PencilAltIcon fontSize="small" />}
                                        onClick={() =>{navigate('/dashboard/subscriptions/edit/'+subscription.id);}}
                                        variant="text">
                                        {i18n.t("modifica")}
                                    </Button>
                                ) : null}
                            </Box>
                        </Grid>
                    </Grid>
                    <Box sx={{ py: 3 ,
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        flexGrow: 1}}>
                        <Container maxWidth="md">
                            <Typography
                                align="center"
                                color="textPrimary"
                                sx={{
                                    fontWeight: 'fontWeightBold',
                                    alignItems: 'center',
                                    mt: 3
                                }}
                                variant="h2"
                            >
                                {subscription.description}
                            </Typography>
                        </Container>
                        <Box sx={{ mt: 6, backgroundColor: "white", mb: 6}}>
                            <img
                                height={400}
                                width={400}
                                src={S3Controller.digitalOceanSpaces+"/"+subscription.image}
                            />
                        </Box>
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <Typography
                                color="textPrimary"
                                variant="h6"
                            >
                                {i18n.t("currency_symbol")}
                            </Typography>
                            <Typography
                                color="textPrimary"
                                variant="h4"
                            >
                                {subscription.price.toString()}
                            </Typography>
                            <Typography
                                color="textSecondary"
                                sx={{
                                    alignSelf: 'flex-end',
                                    ml: 1
                                }}
                                variant="subtitle2"
                            >
                            </Typography>
                        </Box>

                        <Typography
                            color="textSecondary"
                            sx={{ mt: 2 }}
                            variant="body2"
                        >
                            {subscription.description}
                        </Typography>
                        <Divider sx={{width: 300, mt: 4}}/>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column',
                            flexGrow: 1,
                            p: 2
                        }}
                    >
                        {getFeatures(subscription).map((feature) => (
                            <Box
                                key={feature}
                                sx={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    '& + &': {
                                        mt: 2
                                    }
                                }}
                            >
                                <CheckIcon
                                    fontSize="small"
                                    sx={{ color: 'text.primary' }}
                                />
                                <Typography
                                    color="textPrimary"
                                    sx={{
                                        fontWeight: 500,
                                        ml: 2
                                    }}
                                    variant="body2"
                                >
                                    {feature}
                                </Typography>
                            </Box>
                        ))}
                        <Button
                            color="primary"
                            sx={{mt: 5,minWidth: 400}}
                            startIcon={<ShoppingCartIcon fontSize="small" />}
                            variant="outlined"
                            onClick={() =>{
                                if(isNewsletter){
                                    window.open('https://micromegaedizioni.net/abbonati-a-mmpiu/', '_blank');
                                }else{
                                    if(subscription.duration === 1){
                                        /*** DEVE SELEZIONARE LA COPIA SINGOLA O ARRETRATO**/
                                        navigate('/dashboard/products');
                                    }else{
                                        navigate('/checkout', {state: {subscriptionType: subscription}});
                                    }
                                }
                            }
                            }>
                            {i18n.t("acquista") + " "+ numeral(subscription.price).format(`0,0.00`)+" "+i18n.t("currency_symbol")}
                        </Button>
                    </Box>
                    {
                    /*<Box
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                            flexDirection: 'column',
                            pt: 3,
                            mt: 10,
                            px: 3
                        }}
                    >
                        <Typography
                            color="textPrimary"
                            variant="h3"
                        >
                            {i18n.t("prodotti").toUpperCase()}
                        </Typography>
                        <SubscriptionList singola={true} card={true}/>
                        <Typography
                            color="textPrimary"
                            variant="h3"
                        >
                            {i18n.t("abbonamenti_italia")}
                        </Typography>
                        <SubscriptionList singola={false} card={true} filterSpedizione={SubscriptionTypeSpedizione.Nazionali}/>
                        <Typography
                            color="textPrimary"
                            variant="h3"
                        >
                            {i18n.t("abbonamenti_europa")}
                        </Typography>
                        <SubscriptionList singola={false} card={true} filterSpedizione={SubscriptionTypeSpedizione.Europee}/>
                        <Typography
                            color="textPrimary"
                            variant="h3"
                        >
                            {i18n.t("abbonamenti_mondo")}
                        </Typography>
                        <SubscriptionList singola={false} card={true} filterSpedizione={SubscriptionTypeSpedizione.Mondo}/>
                    </Box>
                    */}
                </Container>
            </Box>
        </>
    );
};

export default SubscriptionDashboardDetail;
