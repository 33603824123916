import { useEffect } from 'react';
import type { FC } from 'react';
import {Link as RouterLink, useLocation, useNavigate} from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Link,
  Typography
} from '@material-ui/core';
import Logo from '../../components/Logo';
import i18n from "i18next";
import LoginEmail from "../../components/authentication/login/LoginEmail";
import {Location} from "history";
import {SubscriptionType} from "../../model/types/subscriptionType";

interface LocationState {
    subscriptionType?: SubscriptionType;
}
const Login: FC = () => {
    const location = useLocation() as Location<LocationState>;
    const navigate = useNavigate();
    console.log("LOGIN LOCATION STATE",location)
    return (
    <>
      <Helmet>
        <title>{i18n.t("accedi")} | Micromega</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh'
        }}
      >
        <Container
          maxWidth="sm"
          sx={{ py: '80px' }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mb: 8
            }}
          >
            <RouterLink to="/">
              <Logo
                sx={{
                  height: 40,
                  width: 40
                }}
              />
            </RouterLink>
          </Box>
          <Card>
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                p: 4
              }}
            >
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'space-between',
                  mb: 3
                }}
              >
                <div>
                  <Typography
                    color="textPrimary"
                    gutterBottom
                    variant="h4">
                      {i18n.t("accedi")}
                  </Typography>
                  <Typography
                    color="textSecondary"
                    variant="body2">
                      {i18n.t("subtitleLogin")}
                  </Typography>
                </div>

              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  mt: 3
                }}
              >
                  <LoginEmail />
              </Box>
              <Divider sx={{ my: 3 }} />
              <Typography
                color="textSecondary"
                sx={{
                    cursor: 'pointer',
                    textDecoration: 'none', // Rimuovi l'underline predefinito
                    transition: 'text-decoration 0.2s', // Aggiungi una transizione per una transizione più fluida
                    '&:hover': {
                        textDecoration: 'underline', // Applica l'underline al passaggio del mouse
                    }
                }}
                onClick={() =>{
                    if(location?.state?.subscriptionType) {
                        navigate("/authentication/register", {state: {subscriptionType: location?.state.subscriptionType}});
                    }else {
                        navigate("/authentication/register");
                    }
                }}
                variant="body2"
              >
                  {i18n.t("registrati")}
              </Typography>
                <Link
                    color="textSecondary"
                    component={RouterLink}
                    sx={{ mt: 1 }}
                    to="/authentication/password-recovery"
                    variant="body2">
                    {i18n.t("password_dimenticata")}
                </Link>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default Login;
