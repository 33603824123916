import {isValidDate} from "@fullcalendar/react";

export class DateUtils{
    public static parsedDate(str: string): Date{
        const data = new Date(Date.parse(str.replace(/-/g, '/').replace(/[a-z]+/gi, ' ')));
        if(data && isValidDate(data)){
            return data
        }else{
            return DateUtils.dateFromISO8601(str)
        }
    }
    public static dateFromISO8601(isoDateString) {
        const parts = isoDateString.match(/\d+/g);
        const isoTime = Date.UTC(parts[0], parts[1] - 1, parts[2], parts[3], parts[4], parts[5]);
        return new Date(isoTime);
    }
}