import {ApiUtil} from "../apiUtils";
import {
    SubscriptionTypeCreateDTO,
    SubscriptionTypeDeleteDTO,
    SubscriptionTypeResponseArrayDTO,
    SubscriptionTypeResponseDTO
} from "../../model/dto/subscriptionType.dto";
import {SubscriptionType} from "../../model/types/subscriptionType";

export class SubscriptionTypeController {
    public static async find(id: string): Promise<SubscriptionTypeResponseDTO> {
        return await ApiUtil.makeRequest<SubscriptionTypeResponseDTO>(
            "get",
            "subscriptionType/"+id,
            true,
            null,
        );
    }
    public static async create(subscriptionTypeCreateDTO: SubscriptionTypeCreateDTO): Promise<SubscriptionTypeResponseDTO> {
        return await ApiUtil.makeRequest<SubscriptionTypeResponseDTO>(
            "put",
            "subscriptionType",
            true,
            subscriptionTypeCreateDTO,
        );
    }
    public static async update(subscriptionType: SubscriptionType): Promise<SubscriptionTypeResponseDTO> {
        return await ApiUtil.makeRequest<SubscriptionTypeResponseDTO>(
            "post",
            "subscriptionType",
            true,
            subscriptionType,
        );
    }
    public static async delete(subscriptionTypeDeleteDTO: SubscriptionTypeDeleteDTO): Promise<{ result: string }> {
        return await ApiUtil.makeRequest<{ result: string }>(
            "delete",
            "subscriptionType/"+subscriptionTypeDeleteDTO.id,
            true,
            null,
        );
    }
    public static async findAll(): Promise<SubscriptionTypeResponseArrayDTO> {
        return await ApiUtil.makeRequest<SubscriptionTypeResponseArrayDTO>(
            "get",
            "subscriptionTypeAll",
            true,
            null,
        );
    }
    public static async findAllActive(): Promise<SubscriptionTypeResponseArrayDTO> {
        return await ApiUtil.makeRequest<SubscriptionTypeResponseArrayDTO>(
            "get",
            "subscriptionTypeAllActive",
            true,
            null,
        );
    }

}
