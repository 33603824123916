import i18n from "i18next";
import {SubscriptionType, SubscriptionTypeSpedizione} from "./subscriptionType";

export class State{
    constructor(name: string,key: string, priceShipping: number) {
        this.name = name;
        this.key = key;
        this.priceShipping = priceShipping;
    }
    name: string
    key: string
    priceShipping: number
    public static getListState( fromDB: boolean,subscriptionType?: SubscriptionType){
        if(fromDB || !subscriptionType || (subscriptionType && subscriptionType.spedizione === SubscriptionTypeSpedizione.Nazionali && subscriptionType.cartaceo)){
            const stati = Array<State>();
            stati.push(new State("","",0))
            stati.push(new State(i18n.t("italia"), "italia", 0));
            return stati;
        }
        const stati = Array<State>();
        stati.push(new State("","",0))
        stati.push(new State(i18n.t("italia"), "italia", 0));
        stati.push(new State(i18n.t("austria"), "austria", 0));
        stati.push(new State(i18n.t("bulgaria"), "bulgaria", 0));
        stati.push(new State(i18n.t("cipro"), "cipro", 0));
        stati.push(new State(i18n.t("croazia"), "croazia", 0));
        stati.push(new State(i18n.t("danimarca"), "danimarca", 0));
        stati.push(new State(i18n.t("estonia"), "estonia", 0));
        stati.push(new State(i18n.t("finlandia"), "finlandia", 0));
        stati.push(new State(i18n.t("francia"), "francia", 0));
        stati.push(new State(i18n.t("germania"), "germania", 0));
        stati.push(new State(i18n.t("grecia"), "grecia", 0));
        stati.push(new State(i18n.t("irlanda"), "irlanda", 0));
        stati.push(new State(i18n.t("inghilterra"), "inghilterra", 0));
        stati.push(new State(i18n.t("lettonia"), "lettonia", 0));
        stati.push(new State(i18n.t("lituania"), "lituania", 0));
        stati.push(new State(i18n.t("lussemburgo"), "lussemburgo", 0));
        stati.push(new State(i18n.t("malta"), "malta", 0));
        stati.push(new State(i18n.t("paesi_bassi"), "paesi_bassi", 0));
        stati.push(new State(i18n.t("polonia"), "polonia", 0));
        stati.push(new State(i18n.t("portogallo"), "portogallo", 0));
        stati.push(new State(i18n.t("romania"), "romania", 0));
        stati.push(new State(i18n.t("slovacchia"), "slovacchia", 0));
        stati.push(new State(i18n.t("slovenia"), "slovenia", 0));
        stati.push(new State(i18n.t("spagna"), "spagna", 0));
        stati.push(new State(i18n.t("svezia"), "svezia", 0));
        stati.push(new State(i18n.t("ungheria"), "ungheria", 0));
        stati.push(new State(i18n.t("stati_uniti"), "stati_uniti", 0));
        stati.push(new State(i18n.t("canada"), "canada", 0));
        stati.push(new State(i18n.t("argentina"), "argentina", 0));
        stati.push(new State(i18n.t("brasile"), "brasile", 0));
        stati.push(new State(i18n.t("venezuela"), "venezuela", 0));
        stati.push(new State(i18n.t("israele"), "israele", 0));
        stati.push(new State(i18n.t("turchia"), "turchia", 0));
        stati.push(new State(i18n.t("armenia"), "armenia", 0));
        stati.push(new State(i18n.t("azerbaigian"), "azerbaigian", 0));
        stati.push(new State(i18n.t("bielorussia"), "bielorussia", 0));
        stati.push(new State(i18n.t("georgia"), "georgia", 0));
        stati.push(new State(i18n.t("kazakistan"), "kazakistan", 0));
        stati.push(new State(i18n.t("kirghizistan"), "kirghizistan", 0));
        stati.push(new State(i18n.t("moldavia"), "moldavia", 0));
        stati.push(new State(i18n.t("russia"), "russia", 0));
        stati.push(new State(i18n.t("tagikistan"), "tagikistan", 0));
        stati.push(new State(i18n.t("turkmenistan"), "turkmenistan", 0));
        stati.push(new State(i18n.t("ucraina"), "ucraina", 0));
        stati.push(new State(i18n.t("uzbekistan"), "uzbekistan", 0));
        stati.push(new State(i18n.t("australia"), "australia", 0));
        stati.push(new State(i18n.t("nuova_zelanda"), "nuova_zelanda", 0));
        stati.push(new State(i18n.t("giappone"), "giappone", 0));
        stati.push(new State(i18n.t("cina"), "cina", 0));
        stati.push(new State(i18n.t("india"), "india", 0));
        stati.push(new State(i18n.t("corea_del_sud"), "corea_del_sud", 0));
        stati.push(new State(i18n.t("sud_africa"), "sud_africa", 0));
        stati.push(new State(i18n.t("egitto"), "egitto", 0));
        stati.push(new State(i18n.t("marocco"), "marocco", 0));
        stati.push(new State(i18n.t("kenia"), "kenia", 0));
        stati.push(new State(i18n.t("nigeria"), "nigeria", 0));
        stati.push(new State(i18n.t("messico"), "messico", 0));
        stati.push(new State(i18n.t("colombia"), "colombia", 0));
        stati.push(new State(i18n.t("perù"), "perù", 0));
        stati.push(new State(i18n.t("cile"), "cile", 0));
        stati.push(new State(i18n.t("ecuador"), "ecuador", 0));
        stati.push(new State(i18n.t("svizzera"), "svizzera", 0));

        stati.sort((a, b) => a.name.localeCompare(b.name));

        return stati;
    }
    public static getOnlyItListState(){
        const stati = Array<State>();
        stati.push(new State("","",0))
        stati.push(new State(i18n.t("italia"), "italia", 0));


        stati.sort((a, b) => a.name.localeCompare(b.name));

        return stati;
    }
}

