import {SubscriptionType} from "../types/subscriptionType";

export class SubscriptionTypeCreateDTO{
    description: string
    duration: number
    status: number
    price: number
    archivio: boolean
    cartaceo: boolean
    digitale: boolean
    spedizione: string
    newsletter: boolean
    image: string
    order: number

    constructor(description: string, duration: number, price: number, archivio: boolean, cartaceo: boolean, digitale: boolean, spedizione: string, newsletter: boolean,image: string,order: number) {
        this.description = description;
        this.duration = duration;
        this.price = price;
        this.archivio = archivio;
        this.cartaceo = cartaceo;
        this.digitale = digitale;
        this.spedizione = spedizione;
        this.newsletter = newsletter;
        this.status = 1;
        this.image = image
        this.order = order
    }
}
export class SubscriptionTypeDeleteDTO{
    id:number
    constructor(id: number) {
        this.id = id;
    }
}

//RESPONSE
export class SubscriptionTypeResponseDTO{
    data: SubscriptionType
}
export class SubscriptionTypeResponseArrayDTO{
    data: Array<SubscriptionType>
}