import {
    AuthResponseDTO, ChangePasswordAdminDTO,
    ChangePasswordDTO,
    DeviceResponseDTO,
    EditPasswordDTO,
    RegisterDTO,
    SignupResponseDTO,
    TokenFcmResponseDTO,
    UserResponseDTO
} from "../../model/dto/user.dto";
import {ApiUtil} from "../apiUtils";
import {User} from "../../model/types/user";
import {GenericResponseArrayDTO, GenericResponseEmptyDTO} from "../../model/dto/generic.dto";
import {AuthContextHelper} from "../../contexts/JWTContext";


export class UserController {

    public static async sign(email: string, password: string): Promise<AuthResponseDTO> {
        return await ApiUtil.makeRequest<AuthResponseDTO>(
            "post",
            "auth/signin",
            false,
            {email: email, password: password, uuid: localStorage.getItem("uuid"),os: "web",tokenFcm: "web" /*TODO*/},
        );
    }
    public static async register(registerDTO: RegisterDTO): Promise<SignupResponseDTO> {
        return await ApiUtil.makeRequest<SignupResponseDTO>(
            "post",
            "auth/signup",
            false,
            registerDTO,
        );
    }
    public static async add(registerDTO: RegisterDTO): Promise<SignupResponseDTO> {
        return await ApiUtil.makeRequest<SignupResponseDTO>(
            "post",
            "user/add",
            true,
            registerDTO,
        );
    }
    public static async passwordRecovery(email: string): Promise<SignupResponseDTO> {
        return await ApiUtil.makeRequest<SignupResponseDTO>(
            "post",
            "auth/recoveryPassword",
            false,
            {email: email},
        );
    }
    public static async changePassword(changePassword: ChangePasswordDTO): Promise<SignupResponseDTO> {
        return await ApiUtil.makeRequest<SignupResponseDTO>(
            "post",
            "auth/changePassword",
            false,
            changePassword,
        );
    }
    public static async profile(): Promise<UserResponseDTO> {
        return await ApiUtil.makeRequest<UserResponseDTO>(
            "get",
            "user",
            true,
            null,
        );
    }
    public static async devices(userId: string): Promise<DeviceResponseDTO> {
        return await ApiUtil.makeRequest<DeviceResponseDTO>(
            "get",
            "user/device/"+userId,
            true,
            null,
        );
    }
    public static async resetDevice(userId: string): Promise<GenericResponseEmptyDTO> {
        return await ApiUtil.makeRequest<GenericResponseEmptyDTO>(
            "post",
            "user/resetDevice/"+userId,
            true,
            {},
        );
    }
    public static async updateTokenFcm(tokenFcm: string): Promise<GenericResponseEmptyDTO> {
        return await ApiUtil.makeRequest<GenericResponseEmptyDTO>(
            "post",
            "user/device",
            true,
            {os:"web",tokenFcm: tokenFcm,uuid: localStorage.getItem("uuid")},
        );
    }

    public static async updateProfile(user: User): Promise<UserResponseDTO> {
        return await ApiUtil.makeRequest<UserResponseDTO>(
            "post",
            User.isAdmin(AuthContextHelper.getAuthContext().user) ? "user/update" : "user",
            true,
            user,
        );
    }
    public static async editPassword(editPasswordDto: EditPasswordDTO): Promise<UserResponseDTO> {
        return await ApiUtil.makeRequest<UserResponseDTO>(
            "post",
            "user/editPassword",
            true,
            editPasswordDto,
        );
    }
    public static async changePasswordAdmin(changePasswordAdminDTO: ChangePasswordAdminDTO): Promise<UserResponseDTO> {
        return await ApiUtil.makeRequest<UserResponseDTO>(
            "post",
            "user/changePassword",
            true,
            changePasswordAdminDTO,
        );
    }
    public static async getUserById(id: string): Promise<UserResponseDTO> {
        return await ApiUtil.makeRequest<UserResponseDTO>(
            "get",
            "userById/"+id,
            true,
            null,
        );
    }
    public static async all(): Promise<GenericResponseArrayDTO<User>> {
        return await ApiUtil.makeRequest<GenericResponseArrayDTO<User>>(
            "get",
            "userAll",
            true,
            null,
        );
    }
}
