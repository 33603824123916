import type { FC } from 'react';
import type { Theme } from '@material-ui/core';
import type { SxProps } from '@material-ui/system';
import logo from '../res/img/micromega_icona.png';

interface LogoProps {
  sx?: SxProps<Theme>;
}
const Logo: FC<LogoProps> = (props) => (
  <div>
    <img src={logo} width="52" height="52"/>
  </div>
);

export default Logo;
