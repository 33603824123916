import type { FC } from 'react';
import {Link as RouterLink, useNavigate} from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
    Box,
    Breadcrumbs,
    Button, Card, CardHeader,
    Container, Divider, FormHelperText,
    Grid,
    Link, TextField,
    Typography
} from '@material-ui/core';
import ChevronRightIcon from '../../../res/icons/ChevronRight';
import useSettings from '../../../hooks/useSettings';
import {User} from "../../../model/types/user";
import {UserController} from "../../../api/controller/user.controller";
import i18n from "i18next";
import toast from 'react-hot-toast';
import {Formik} from "formik";
import * as Yup from "yup";
import {RegisterDTO} from "../../../model/dto/user.dto";
import {MobileDatePicker} from "@material-ui/lab";

const CustomerAdd: FC = (props) => {
    const { settings } = useSettings();
    const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title> {i18n.t("aggiungi_utente")} | Micromega</title>
      </Helmet>
        <Container sx={{ m: 3 }}
                   maxWidth={settings.compact ? 'xl' : false}>
            <Grid
                container
                justifyContent="space-between"
                spacing={3}
            >
                <Grid item>
                    <Typography
                        color="textPrimary"
                        variant="h5"
                    >
                        {i18n.t("aggiungi_utente")}
                    </Typography>
                    <Breadcrumbs
                        aria-label="breadcrumb"
                        separator={<ChevronRightIcon fontSize="small" />}
                        sx={{ mt: 1 }}
                    >
                        <Link
                            color="textPrimary"
                            component={RouterLink}
                            to="/dashboard"
                            variant="subtitle2"
                        >
                            {i18n.t("dashboard")}
                        </Link>
                        <Link
                            color="textPrimary"
                            component={RouterLink}
                            to="/dashboard/customers"
                            variant="subtitle2"
                        >
                            {i18n.t("utenti")}
                        </Link>

                    </Breadcrumbs>
                </Grid>
            </Grid>
            <Grid
                container
                spacing={4}
                sx={{mt: 2}}
                {...props}
            >
                <Grid
                    item
                    lg={5}
                    md={5}
                    xl={5}
                    xs={12}>
                    <Formik
                        initialValues={{
                            email: '',
                            name: '',
                            cognome: "",
                            cellulare: "",
                            dataNascita: new Date(),
                            sesso: i18n.t("altro"),
                            password: '',
                            submit: null
                        }}
                        validationSchema={
                            Yup
                                .object()
                                .shape({
                                    email: Yup
                                        .string()
                                        .email(i18n.t("valid_email"))
                                        .max(255)
                                        .required(i18n.t("required_email")),
                                    name: Yup
                                        .string()
                                        .max(255)
                                        .required(i18n.t("required_name")),
                                    cognome: Yup
                                        .string()
                                        .max(255)
                                        .required(i18n.t("required_cognome")),
                                    cellulare: Yup
                                        .string()
                                        .max(255)
                                        .required(i18n.t("required_cellulare")),
                                    sesso: Yup
                                        .string()
                                        .max(255),
                                    dataNascita: Yup
                                        .date(),
                                    password: Yup
                                        .string()
                                        .min(7)
                                        .max(255)
                                        .required(i18n.t("required_password"))
                                })
                        }
                        onSubmit={async (values, {
                            setErrors,
                            setStatus,
                            setSubmitting
                        }): Promise<void> => {
                            try {
                                const resp = await UserController.add(new RegisterDTO(values.email,values.password,values.name,values.cognome,values.sesso,values.cellulare,0,values.dataNascita.toISOString()));
                                if (resp) {
                                    setStatus({ success: true });
                                    setSubmitting(false);
                                    let tostMsg = i18n.t("utente_creato");
                                    toast.success(tostMsg);
                                    navigate('/dashboard/customers/'+resp.data.user.id);
                                }
                            } catch (err) {
                                console.error(err);
                                setStatus({ success: false });
                                setErrors({ submit: err.message });
                                let toastMsg = i18n.t("problemi_elaborazione")
                                toast.error(toastMsg);
                                setErrors({ submit: err.stack.msg ? err.stack.msg : err.message})
                                setSubmitting(false);
                            }
                        }}>
                        {({
                              errors,
                              handleBlur,
                              handleChange,
                              handleSubmit,
                              isSubmitting,
                              touched,
                              values,
                              setFieldValue
                          }): JSX.Element => (
                            <form
                                noValidate
                                onSubmit={handleSubmit}
                                {...props}
                            >
                                <Card>
                                    <CardHeader title={i18n.t("aggiungi_utente")} />
                                    <Divider />
                                    <Grid
                                        container
                                        sx={{p: 2}}
                                        spacing={4}>
                                        <Grid
                                            item
                                            md={12}
                                            xs={12}>
                                            <TextField
                                                error={Boolean(touched.name && errors.name)}
                                                fullWidth
                                                helperText={touched.name && errors.name}
                                                label={i18n.t("name")}
                                                name="name"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.name}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            md={12}
                                            xs={12}>
                                            <TextField
                                                error={Boolean(touched.cognome && errors.cognome)}
                                                fullWidth
                                                helperText={touched.cognome && errors.cognome}
                                                label={i18n.t("cognome")}
                                                name="cognome"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.cognome}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            md={12}
                                            xs={12}>
                                            <TextField
                                                fullWidth
                                                label={i18n.t("sesso")}
                                                name="sesso"
                                                error={Boolean(touched.sesso && errors.sesso)}
                                                helperText={touched.sesso && errors.sesso}
                                                value={values.sesso}
                                                select
                                                SelectProps={{ native: true }}
                                                onChange={handleChange}
                                                variant="outlined"
                                            >
                                                {User.getGenders().map((category) => (
                                                    <option
                                                        key={category.value}
                                                        value={category.value}>
                                                        {category.label}
                                                    </option>
                                                ))}
                                            </TextField>
                                        </Grid>

                                        <Grid
                                            item
                                            md={12}
                                            xs={12}>
                                            <MobileDatePicker
                                                label={i18n.t("data_nascita")}
                                                onChange={date => setFieldValue("dataNascita", date)}
                                                renderInput={(inputProps) => (
                                                    <TextField
                                                        fullWidth
                                                        name="dataNascita"
                                                        error={Boolean(touched.dataNascita && errors.dataNascita)}
                                                        helperText={touched.dataNascita && errors.dataNascita}
                                                        variant="outlined"
                                                        {...inputProps}
                                                    />
                                                )}
                                                value={values.dataNascita}
                                            />
                                        </Grid>

                                        <Grid
                                            item
                                            md={12}
                                            xs={12}>
                                            <TextField
                                                error={Boolean(touched.cellulare && errors.cellulare)}
                                                fullWidth
                                                helperText={touched.cellulare && errors.cellulare}
                                                label={i18n.t("cellulare")}
                                                name="cellulare"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.cellulare}
                                                variant="outlined"
                                            />
                                        </Grid>

                                        <Grid
                                            item
                                            md={12}
                                            xs={12}>
                                            <TextField
                                                error={Boolean(touched.email && errors.email)}
                                                fullWidth
                                                helperText={touched.email && errors.email}
                                                label={i18n.t("email")}
                                                name="email"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                type="email"
                                                value={values.email}
                                                variant="outlined"
                                            />
                                        </Grid>

                                        <Grid
                                            item
                                            md={12}
                                            xs={12}>
                                            <TextField
                                                error={Boolean(touched.password && errors.password)}
                                                fullWidth
                                                helperText={touched.password && errors.password}
                                                label={i18n.t("password")}
                                                name="password"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                type="password"
                                                value={values.password}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item
                                              md={12}
                                              xs={12}>
                                            {errors.submit && (
                                                <Box sx={{ mt: 3 }}>
                                                    <FormHelperText error>
                                                        {errors.submit}
                                                    </FormHelperText>
                                                </Box>
                                            )}
                                            <Box sx={{ mt: 2 }}>
                                                <Button
                                                    color="primary"
                                                    disabled={isSubmitting}
                                                    size="large"
                                                    type="submit"
                                                    variant="contained"
                                                >
                                                    {i18n.t("registralo")}
                                                </Button>
                                            </Box>
                                        </Grid>

                                    </Grid>
                                </Card>

                            </form>
                        )}
                    </Formik>
                </Grid>
                <Grid
                    item
                    lg={5}
                    md={5}
                    xl={5}
                    xs={12}
                >
                </Grid>
            </Grid>
        </Container>
    </>
  );
};

export default CustomerAdd;
