import {Subscription} from "../types/subscription";

export class SubscriptionCreateDTO{
    idType: number
    startDate: string
    method: string
    idUser: number
    amount: number
    fattura: boolean
    spedizione: string
    transactionId: string

    constructor(idType: number, startDate: string, method: string, idUser: number, amount: number, fattura: boolean, spedizione: string,transactionId: string) {
        this.idType = idType;
        this.startDate = startDate;
        this.method = method;
        this.idUser = idUser;
        this.amount = amount;
        this.fattura = fattura;
        this.spedizione = spedizione;
        this.transactionId = transactionId;
    }
}
export class SubscriptionDeleteDTO{
    id:number

    constructor(id: number) {
        this.id = id;
    }
}
//RESPONSE
export class SubscriptionResponseDTO{
    data: Subscription
}
export class SubscriptionResponseArrayDTO{
    data: Array<Subscription>
}