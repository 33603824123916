import type { FC } from 'react';
import useAuth from "../hooks/useAuth";
import {UserController} from "../api/controller/user.controller";
import {SubscriptionType} from "../model/types/subscriptionType";
import {useLocation, useNavigate} from "react-router-dom";
import {Location} from "history";
import {Product} from "../model/types/product";
import Login from "./authentication/Login";
import {useEffect, useState} from "react";
import i18n from "i18next";
import toast from "react-hot-toast";
import {ProductController} from "../api/controller/product.controller";
import {SubscriptionTypeController} from "../api/controller/subscriptionType.controller";

interface LocationState {
    subscriptionType?: SubscriptionType;
    products: Array<Product>,
    cartaceo: boolean
}

const CheckoutMobile: FC = () => {
    const {user,setUser} = useAuth() as any;
    const location = useLocation() as Location<LocationState>;
    const [noParam, setNoParam] = useState<Boolean>(false);
    const navigate = useNavigate();
    useEffect(() => {
        (
            async () => {
                const queryParams = new URLSearchParams(location.search);
                const token = queryParams?.get("token");
                const subscription = queryParams?.get("subscription");
                const idItem = queryParams?.get("idItem");
                const cartaceo = queryParams?.get("cartaceo");
                if(token && subscription && idItem){
                    localStorage.setItem("accessToken",token)
                    try {
                        const resp = await UserController.profile()
                        setUser(resp.user)
                        if(subscription === "true"){
                            const subscriptionType = await SubscriptionTypeController.find(idItem)
                            navigate('/checkout', {state: {subscriptionType: subscriptionType.data}})
                        }else{
                            const product = await ProductController.find(idItem)
                            navigate('/checkout', {state: {products: [product.data],cartaceo: cartaceo === "true"}})
                        }
                    } catch (err) {
                        let testoToast = i18n.t("problemi_nel_caricamento")
                        toast.error(testoToast)
                        console.error(err);
                        setNoParam(true)
                    }
                }else{
                    setNoParam(true)
                }
            }
        )();
    }, []);
    if(noParam){
        return (<Login />)
    }else {
        return (<div></div>)
    }
};

export default CheckoutMobile;
