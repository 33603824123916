import { useRef, useState } from 'react';
import type { FC } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Divider,
  Popover,
  Typography
} from '@material-ui/core';
import useAuth from '../../hooks/useAuth';
import i18n from "i18next";
import getInitials from "../../utils/getInitials";

const AccountPopover: FC = () => {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleLogout = async (): Promise<void> => {
    try {
      handleClose();
      await logout();
      navigate('/');
    } catch (err) {
      console.error(err);
      let textToast = i18n.t("non_sono_riuscito_logout");
      toast.error(textToast);
    }
  };
  return (
    <>
      <Box
          component={ButtonBase}
          onClick={handleOpen}
          ref={anchorRef}
          sx={{
            alignItems: 'center',
            display: 'flex'
          }}
      >
        <Avatar
            src={user ? user.avatar : null}
            sx={{
              height: 32,
              width: 32
            }}
        >
            {getInitials(user ? user.nome+" "+user.cognome : i18n.t("guest"))}
        </Avatar>
      </Box>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom'
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 240 }
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography
            color="textPrimary"
            variant="subtitle2"
          >
            {user ? (user.nome + " "+user.cognome) : i18n.t("guest")}
          </Typography>
        </Box>

          <Divider />
          <Box sx={{p:2 }}>
              <Button
                  color="primary"
                  fullWidth
                  onClick={()=>{
                      navigate('/dashboard');

                  }}
                  variant="outlined"
              >
                  {i18n.t("overview")}
              </Button>
          </Box>
          {
              user ? (
                  <Box sx={{ pl: 2 , pr: 2, pb: 2}}>
                      <Button
                          color="primary"
                          fullWidth
                          onClick={handleLogout}
                          variant="outlined"
                      >
                          {i18n.t("logout")}
                      </Button>
                  </Box>
              ): (
                  <>
                      <Box sx={{ pl: 2 , pr: 2, pb: 2}}>
                          <Button
                              color="primary"
                              component="a"
                              href="/authentication/login"
                              fullWidth
                              variant="contained"
                          >
                              {i18n.t("accedi")}
                          </Button>
                      </Box>
                      <Box sx={{ pl: 2 , pr: 2, pb: 2}}>
                          <Button
                              color="secondary"
                              component="a"
                              href="/authentication/register"
                              fullWidth
                              variant="contained"
                          >
                              {i18n.t("registrati")}
                          </Button>

                      </Box>
                  </>
              )
          }
      </Popover>
    </>
  );
};

export default AccountPopover;
