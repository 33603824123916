import {ApiUtil} from "../apiUtils";
import {
    ProductCreateDTO,
    ProductDeleteDTO,
    ProductResponseArrayDTO,
    ProductResponseDTO
} from "../../model/dto/product.dto";
import {Product} from "../../model/types/product";


export class ProductController {
    public static async find(id: string): Promise<ProductResponseDTO> {
        return await ApiUtil.makeRequest<ProductResponseDTO>(
            "get",
            "product/"+encodeURIComponent(id),
            true,
            null,
        );
    }
    public static async create(productCreateDTO: ProductCreateDTO): Promise<ProductResponseDTO> {
        return await ApiUtil.makeRequest<ProductResponseDTO>(
            "put",
            "product",
            true,
            productCreateDTO,
        );
    }
    public static async update(product: Product): Promise<ProductResponseDTO> {
        return await ApiUtil.makeRequest<ProductResponseDTO>(
            "post",
            "product",
            true,
            product,
        );
    }
    public static async delete(productDeleteDTO: ProductDeleteDTO): Promise<{ result: string }> {
        return await ApiUtil.makeRequest<{ result: string }>(
            "delete",
            "product/"+productDeleteDTO.id,
            true,
            null,
        );
    }
    public static async findAll(): Promise<ProductResponseArrayDTO> {
        return await ApiUtil.makeRequest<ProductResponseArrayDTO>(
            "get",
            "productAll",
            false,
            null,
        );
    }
}
