import {ApiUtil} from "../apiUtils";
import {
    CouponCreateDTO,
    CouponDeleteDTO,
    CouponResponseArrayDTO,
    CouponResponseDTO
} from "../../model/dto/coupon.dto";
import {Coupon} from "../../model/types/coupon";


export class CouponController {
    public static async find(id: string): Promise<CouponResponseDTO> {
        return await ApiUtil.makeRequest<CouponResponseDTO>(
            "get",
            "coupon/"+encodeURIComponent(id),
            true,
            null,
        );
    }
    public static async create(couponCreateDTO: CouponCreateDTO): Promise<CouponResponseDTO> {
        return await ApiUtil.makeRequest<CouponResponseDTO>(
            "put",
            "coupon",
            true,
            couponCreateDTO,
        );
    }
    public static async update(coupon: Coupon): Promise<CouponResponseDTO> {
        return await ApiUtil.makeRequest<CouponResponseDTO>(
            "post",
            "coupon",
            true,
            coupon,
        );
    }
    public static async delete(couponDeleteDTO: CouponDeleteDTO): Promise<{ result: string }> {
        return await ApiUtil.makeRequest<{ result: string }>(
            "delete",
            "coupon/"+couponDeleteDTO.id,
            true,
            null,
        );
    }
    public static async findAll(): Promise<CouponResponseArrayDTO> {
        return await ApiUtil.makeRequest<CouponResponseArrayDTO>(
            "get",
            "couponAll",
            true,
            null,
        );
    }
}
