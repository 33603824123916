import {ApiUtil} from "../apiUtils";
import {
    SubscriptionCreateDTO,
    SubscriptionDeleteDTO, SubscriptionResponseArrayDTO,
    SubscriptionResponseDTO
} from "../../model/dto/subscription.dto";
import {Subscription} from "../../model/types/subscription";


export class SubscriptionController {
    public static async find(id: string): Promise<SubscriptionResponseDTO> {
        return await ApiUtil.makeRequest<SubscriptionResponseDTO>(
            "get",
            "subscription/"+id,
            true,
            null,
        );
    }
    public static async create(subscriptionCreateDTO: SubscriptionCreateDTO): Promise<SubscriptionResponseDTO> {
        return await ApiUtil.makeRequest<SubscriptionResponseDTO>(
            "put",
            "subscription",
            true,
            subscriptionCreateDTO,
        );
    }
    public static async update(subscription: Subscription): Promise<SubscriptionResponseDTO> {
        return await ApiUtil.makeRequest<SubscriptionResponseDTO>(
            "post",
            "subscription",
            true,
            subscription,
        );
    }
    public static async delete(subscriptionDeleteDTO: SubscriptionDeleteDTO): Promise<{ result: string }> {
        return await ApiUtil.makeRequest<{ result: string }>(
            "delete",
            "subscription/"+subscriptionDeleteDTO.id,
            true,
            null,
        );
    }
    public static async findAll(): Promise<SubscriptionResponseArrayDTO> {
        return await ApiUtil.makeRequest<SubscriptionResponseArrayDTO>(
            "get",
            "subscriptionAll",
            true,
            null,
        );
    }

}
