import {User} from "../types/user";
import {Device} from "../types/device";
//PAYLOAD
export class RegisterDTO{
    email: string
    password: string
    nome: string
    cognome: string
    sesso: string
    cellulare: string
    dataNascita?: string
    uuid: string
    os: string
    tokenFcm: string
    newsletter: number

    constructor(email: string, password: string, nome: string, cognome: string, sesso: string, cellulare: string,newsletter: number, dataNascita?: string,) {
        this.email = email;
        this.password = password;
        this.nome = nome;
        this.cognome = cognome;
        this.sesso = sesso;
        this.cellulare = cellulare;
        this.dataNascita = dataNascita;
        this.uuid = localStorage.getItem("uuid")
        this.os = "web"
        this.tokenFcm = "web" //TODO
        this.newsletter = newsletter
    }
}

export class ChangePasswordDTO{
    email: string;
    password: string;
    code: string;

    constructor(email: string, password: string, code: string) {
        this.email = email;
        this.password = password;
        this.code = code;
    }
}

export class EditPasswordDTO{
    currentPassword: string;
    newPassword: string;

    constructor(currentPassword: string, newPassword: string) {
        this.currentPassword = currentPassword;
        this.newPassword = newPassword;
    }
}

export class ChangePasswordAdminDTO{
    id: string;
    newPassword: string;

    constructor(id: string, newPassword: string) {
        this.id = id;
        this.newPassword = newPassword;
    }
}

//RESPONSE
export class AuthResponseDTO{
    data:{
        token: string
        user: User
    }
}
export class SignupResponseDTO{
    data:{
        token: string
        user: User
    }
}
export class UserResponseDTO {
    user: User
}
export class TokenFcmResponseDTO{
    user: User
}
export class DeviceResponseDTO {
    result: number
    data: Array<Device>
}