import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import * as Yup from "yup";

const resources = {
  en: {
    translation: {
        'registrati': "Registrati",
        'password_dimenticata': "Password dimenticata?",
        'accedi': "Accedi",
        'subtitleLogin': "Utilizza le tue credenziali per accedere alla piattaforma",
        'email': "E-mail",
        'password': "Password",
        "valid_email": "Inserisci una mail valida",
        "required_email": 'L\'indirizzo email è obbligatorio',
        "required_password": 'La password è obbligatoria',
        'subtitleRegister': "Registrandoti a MicroMega potrai acquistare prodotti,abbonamenti ed accedere a contenuti esclusivi",
        "already_registered": "Sei già registrato?",
        "required_name": "Il nome è obbligatorio",
        "privacy_required": "Il campo privacy deve essere accettato.",
        "ho_letto_e_accetto": "Ho letto ed accetto",
        "termini_condizioni": "Termini e condizioni",
        "name": "Nome",
        "cognome": "Cognome",
        "sesso": "Sesso",
        "data_nascita": "Data di nascita",
        "cellulare": "Cellulare",
        "required_cognome": "Il campo cognome è richiesto",
        "required_cellulare": "Il campo cellulare è richiesto",
        "maschio": "Maschio",
        "femmina": "Femmina",
        "altro": "Altro",
        "contact_us": "Contattaci",
        "contact": "Contatti",
        "completa_il_form_di_seguito": "Completa il form di seguito",
        "parla_esperto": "Parla con un esperto",
        "subTitleContact": "Per qualsiasi domanda, dubbio approfondimenti in merito a come funziona il servizio abbonamenti, acquisti delle riviste e dei vari prodotti puoi contattarci,usando il form a fianco oppure via email: info@micromega.net",
        "fullname": "Nome Cognome",
        "azienda": "Azienda",
        "messaggio": "Messaggio",
        "invia": "Invia",
        "scrivendoci_tramite_il_form": "Scrivendoci, stai accettando",
        "e": "and",
        "oggetto": "Oggetto",
        "richiesta_informazioni": "Richiesta informazioni",
        "richiesta_amministrativa": "Richiesta amministrativa",
        "informazioni_privacy": "Informazioni privacy",
        "segnala_un_problema": "Segnala un problema",
        "titleHomeHero": "Scopri il mondo MicroMega",
        "subTitleHomeHero": "Da 35 anni MicroMega è al servizio delle grandi battaglie civili. Puoi ricevere la rivista nel suo caratteristico formato a libro comodamente a casa o leggerla in formato digitale.",
        "subsubTitleHomeHero":"Scegli l'offerta più adatta a te",
        "recupera_password": "Recupera password",
        "required_code": "Il codice è obbligatorio",
        "recuperaPasswordSubtitle": "Utilizza il seguente modulo per recuperare la password, riceverai via email un codice da inserire nel prossimo step insieme alla nuova password.",
        "non_hai_ricevuto_il_codice": "Non hai ricevuto il codice?",
        "password_reset": "Reset password",
        "cambia_password_con_il_codice": "Cambia password con il codice ricevuto via email.",
        "deve_avere_almeno_7_carattere": "Deve avere almeno 7 caratteri.",
        "codice_verifica": "Codice di verfica",
        "conferma_password": "Conferma password",
        "password_recovery": "Password recovery",
        "conferma": "Conferma",
        "overview": "Home",
        "profile": "Profilo",
        "i_miei_ordini": "I miei ordini",
        "prodotti": "Prodotti",
        "abbonamenti": "Abbonamenti",
        "utenti": "Utenti",
        "acquista": "Acquista",
        "currency_symbol": "€",
        "digitale": "Digitale",
        "cartaceo": "Cartaceo",
        "archivio": "Archivio",
        "indietro": "Indietro",
        "complete_order": "Completa ordine",
        "informazioni_pagamentos": "Informazioni per il pagamento",
        "dashboard": "Home",
        "profilo": "Profilo",
        "subtotale": "Subtotale",
        "costo_spedizione": "Costo di spedizione",
        "riepilogo_oridne": "Riepilogo ordine",
        "totale": "Totale",
        "dati_fatturazione": "Dati fatturazione",
        "required_subject": "L'oggetto è obbligatorio",
        "required_testo": "Il testo è obbligatorio",
        "problemi_invio_mail": "Problemi nell'invio della mail, riprova",
        "concludi_ordine": "Concludi ordine",
        "privato": "Privato",
        "privato_o_azienda": "Sei un privato o azienda?",
        "ragioneSociale": "Ragione Sociale",
        "codiceFiscalePIVA": "Codice fiscale/P.iva",
        "citta": "Città",
        "provincia": "Provincia",
        "codiceDest": "Codice destinatario",
        "pec": "Pec",
        "cap": "Cap",
        "indirizzo": "Indirizzo",
        "acquisto_sicuro": "Acquisto sicuro",
        "info_tuo_acquisto": "Il tuo acquisto verrà eseguito tramite braintree con certificazione ssl",
        "creditCard_debitcart": "Carta di credito / Debito",
        "paypal": "Paypal",
        "metodi_pagamento": "Metodi di pagamento",
        "telefono": "Telefono",
        "nome_sulla_carta": "Nome sulla carta",
        "salva": "Salva",
        "il_tuo_abbonamento": "Il tuo abbonamento",
        "profilo_aggiornato": "Profilo aggiornato",
        "editPassword": "Modifica password",
        "cambia": "Cambia",
        "currentPassword": "Password corrente",
        "confermaPassword": "Conferma password",
        "nuovaPassword": "Nuova password",
        "password_aggiornata": "Password aggiornata con successo!",
        "leduePasswordNonCoincidono": "Le due password non coincidono",
        "required_password_old": 'La vecchia password è obbligatoria',
        "required_password_confirm": 'La conferma della password è obbligatoria',
        "faq": "F.A.Q",
        "subTitleFaq": "Qui puoi trovare le risposte alle domande più frequenti.",
        "torna_indietro": "Torna indietro",
        "401_subtitle": "Stai provando ad accedere ad una pagina che richiede l'autenticazione, prosegui la navigazione usando la barra in alto o torna indietro.",
        "401_subtitle_admin": "Non hai i permessi sufficienti per visualizzare questa pagina.",
        "401_title": "Errore 401 non sei autorizzato",
        "bonifico_bancario": "Bonifico bancario",
        "erroreServer": "Errore server",
        "500_title": "Errore 500 problema interno del server",
        "500_subtitle": "La richiesta che hai inviato, non è andata a buon fine, riprova o contatta l'assistenza del servizio tecnico, descrivendo il problema.",
        "non_trovato": "Non trovato",
        "404_title": "Errore 404 risorsa cercata non trovata",
        "404_subtitle": "Il percorso ricerca non è stato trovato, controlla di aver digitato correttamente il link, riprova.",
        "logout": "Logout",
        "riepilogo_abbonamento": "Riepilogo abbonamento",
        "vai": "Vai",
        "importo": "Importo",
        "scadenza": "Scadenza",
        "metodo": "Metodo",
        "stato": "Stato",
        "ordine": "Ordine",
        "dettaglio_ordine": "Dettaglio ordine",
        "ordini": "Ordini",
        "numero": "Numero",
        "cliente": "Cliente",
        "data": "Data",
        "aggiungi_abbonamento": "Aggiungi abbonamento",
        "nome": "Nome",
        "descrizione": "Descrizione",
        "tipologia": "Tipologia",
        "prezzo": "Prezzo",
        "resetDispositivi": "Reset dispositivi",
        "dispositivi": "Dispositivi",
        "non_sono_riuscito_logout": "Non sono riuscito a fare il logout",
        "uuid": "Identificativo device",
        "os": "Sistema operativo",
        "push_notification": "Codice push notifications",
        "cancellato": "Cancellato",
        "standby": "Sospeso",
        "attivo": "Attivo",
        "non_hai_abbonamenti_attivi": "Non hai abbonamenti attivi",
        "non_ha_abbonamenti_attivi": "Non ha abbonamenti attivi",
        "aggiungi_utente": "Aggiungi utente",
        "lista_utenti": "Lista utenti",
        "nome_cognome": "Nome Cognome",
        "id": "ID",
        "in_attesa": "In attesa",
        "ordina_per": "Ordina per",
        "problemi_nel_caricamento": "Problemi nel caricamento",
        "utente": "Utente",
        "device_resettati": "Device reimpostati",
        "registralo": "Registralo",
        "utente_creato": "Utente creato con successo",
        "problema_cambio_password": "Problema cambio password",
        "blocca": "Blocca",
        "attiva": "Attiva",
        "operazione_non_riuscita_riprova": "Operazione non riuscita, riprova",
        "cancella": "Cancella",
        "non_ha_device_registrati": "Non ha devices registrati",
        "assegna_abbonamento": "Assegna abbonamento",
        "subTitleAssegnaAbbonamento": "Seleziona uno degli abbonamenti disponibili, per assegnarlo gratuitamente al cliente",
        "annulla": "Annulla",
        "abbonamento_assegnato": "Abbonamento assegnato con successo",
        "seleziona": "Seleziona",
        "attiva_abbonamento": "Attiva abbonamento",
        "sospendi_abbonamento": "Sospendi abbonamento",
        "aggiornamento_ok": "Aggiornamento andato a buon fine",
        "modifica": "Modifica",
        "aggiungi_tipologia_abbonamento": "Aggiungi tipologia abbonamento",
        "required_description": "Il campo descrizione è obbligatorio",
        "required_duration": "Il campo durata è obbligatorio",
        "required_price": "Il campo prezzo è obbligatorio",
        "cartaceo_required": "Devi selezionare se è cartaceo oppure no",
        "archivio_required": "Devi selezionare se da accesso all'archivio oppure no",
        "aggiungi": "Aggiungi",
        "tipologia_abbonamento_creato": "Tipologia abbonamento creato con successo.",
        "duration": "Durata",
        "price": "Prezzo",
        "tipologie_abbonamenti": "Tipologie abbonamenti",
        "elimina": "Elimina",
        "stato_abbonamento": "Stato abbonamento",
        "aggiorna": "Aggiorna",
        "tipologia_abbonamento": "Tipologia abbonamento",
        "modifica_tipologia_abbonamento": "Modifica tipologia abbonamento",
        "abbonamenti_acquistati": "Abbonamenti acquistati",
        "dettaglio_acquisto_abbonamento": "Dettaglio acquisto abbonamento",
        "startDate": "Data inizio",
        "metodo_di_pagamento": "Metodo di pagamento",
        "in_attivazione": "In attivazione",
        "registratoInData": "Registrato in data",
        "nomeCognome": "Nome e Cognome",
        "stato_utente": "Stato utente",
        "is_azienda": "E' un'azienda",
        "coupon": "Coupon",
        "applica_coupon": "Applica il coupon",
        "nuovo": "Nuovo",
        "codice": "Codice",
        "percentuale": "Percentuale",
        "coupon_creato": "Coupon creato con successo",
        "aggiungi_coupon": "Aggiungi coupon",
        "required_amount": "L'importo è obbligatorio",
        "required_percentage": "Devi indicare se si tratta di percentuale oppure no",
        "coupon_detail": "Dettaglio coupon",
        "aggiornato": "Aggiornato con successo.",
        "modifica_coupon": "Modifica coupon",
        "sospendi": "Sospendi",
        "stato_coupon": "Stato coupon",
        "rimuovi_coupon": "Rimuovi coupon",
        "cart_is_empty": "Il carrello è vuoto, torna indietro e seleziona un prodotto o un abbonamento per procedere.",
        "errore_500": "Errore 500",
        "desideri_la_fattura": "Desideri la fattura ?",
        "si": "Sì",
        "no": "No",
        "fattura": "Fattura",
        "creditCard": "Carta di credito",
        "bonifico": "Bonifico bancario",
        "free_admin": "Assegnato da amministratore",
        "free": "Gratuito",
        "coupon_non_attivo": "Coupon non attivo",
        "data_inizio": "Data inizio",
        "nome_device": "Nome dispositivo",
        "identificativo": "Identificativo",
        "no_name": "Nessun nome",
        "aggiungi_prodotto": "Aggiungi prodotto",
        "cerca_prodotti": "Cerca prodotto...",
        "required_title": "Il titolo è richiesto",
        "required_isbn": "Il codice isbn è richiesto",
        "subscriptionIncluded_required": "Devi indicare se è incluso o no nell'abbonamento",
        "prodotto_creato": "Prodotto creato con successo",
        "isbn": "Isbn",
        "subscriptionIncluded": "Il prodotto è incluso nell'abbonamento?",
        "title": "Titolo",
        "amount": "Importo",
        "select_immagine_di_copertina": "Seleziona l'immagine del prodotto",
        "seleziona_il_libro_digitale": "Seleziona il libro digitale da caricare",
        "libro_digitale": "Libro digitale",
        "rimuovi": "Rimuovi",
        "seleziona_file": "Seleziona file",
        "leggi": "Leggi",
        "nome_file": "Nome file",
        "dimensioni_file": "Dimensioni file",
        "prodotto_creato_con_problemi": "Il prodotto è stato creato con successo, ma l'immagine o l'allegato potrebbero non essere stati caricati correttamente, verifica",
        "modifica_prodotto": "Modifica prodotto",
        "problemi_elaborazione": "Problemi nell'elaborazione, riprova",
        "prodotto_aggiornato": "Prodotto aggiornato",
        "nessun_device_registrato": "Non ci sono dispositivi registrati.",
        "cerca_customer": "Cerca utenti...",
        "di": "di",
        "righe_per_pagine": "Righe per pagina",
        "prodotto": "Prodotto",
        "viewer": "Lettore",
        "disabilita": "Disabilita",
        "abilita": "Abilita",
        "metodo_pagamento_non_riconosciuto": "Il metodo di pagamento non è stato riconosciuto.",
        "pagamento_non_andato_a_buonfine": "Il pagamento non è andato a buon fine, riprova.",
        "problemi_aggiornamento_profilo": "Problemi aggiornamenti profilo",
        "richiedi_cancellazione_account": "Richiedi cancellazione account",
        "informazioni_account": "Informazioni account",
        "account_creato": "Profile creato il:",
        "ultimo_aggiornamento": "Ultimo aggiornamento il:",
        "assegnaProdotto": "Assegna prodotto",
        "subTitleAssegnaProdotto": "Seleziona uno dei prodotti disponibili, da assegnare gratuitamente all'utente scelto.",
        "prodotto_assegnato": "Prodotto assegnato",
        "datiIbanBonifico": "\nBENEFICIARIO: Micromega Edizioni impresa sociale SRL \nIBAN:  IT18N0623003201000064268523\nP.Iva/cod.fiscale - 15971151004\nCAUSALE: Indicare nome, cognome e indirizzo",
        "required_codiceFiscale": 'Il codice fiscale o la p.iva sono obbligatori',
        "required_ragioneSociale": 'La ragione sociale è obbligatorio se sei un\'azienda',
        "required_codiceDest": "Il codice destinatario è obbligatorio",
        "required_pec": "La pec è obbligatoria",
        "required_indirizzo": "L'indirizzo è obbligatorio",
        "required_citta": "La città è obbligatoria",
        "required_provincia": "La provincia è obbligatoria",
        "required_cap": "Il cap è obbligatorio",
        "required_telefono": "Il numero di telefono è obbligatorio",
        "dataScadenza": "Data di scadenza",
        "codiceSicurezza": "Codice di sicurezza",
        "cardNumber": "Numero della carta",
        "required_cardOwner": "Il nome dell'intestario della carta è obbligatorio",
        "required_cardNumber": "Il numero della carta è obbligatorio",
        "required_cardExpirationDate": "La scadenza della carta è obbligatoria",
        "required_cardSecurityCode": "Il codice di sicurezza della carta è obbligatorio",
        "idTransaction":"Id transazione",
        "pubblicita":"Pubblicità",
        "pubblicitaSubTitle":"Vuoi promuovere la tua attività su MicroMega? Scopri come",
        "acquistaSubTitle":"Scopri tutte le opzioni per acquistare e/o abbonarti a MicroMega",
        "donazioneTitle":"Vuoi fare una donazione a MicroMega?",
        "donazioneSubTitle":"Scopri come sostenerci",
        "micromegaplus":"MicroMega+",
        "micromegaplusSubTitle":"Leggi tutti i contenuti esclusivi della nostra newsletter!",
        "newsletter":"Newsletter",
        "spedizioni_nazionali":"Spedizioni nazionali",
        "spedizioni_europee":"Spedizioni europee",
        "spedizioni_internazionali":"Spedizioni internazionali",
        "digitale_required":"Devi indicare se è di tipo digitale o no",
        "newsletter_required":"Devi indicare se è compresa la newsletter o no",
        "spedizione_required":"Devi indicare la tipologia di spedizione",
        "nessuna_spedizione":"Nessuna spedizione",
        "spedizione":"Spedizione",
        "europa":"Europa",
        "mondo":"Mondo",
        "archivio_required_book": "Devi selezionare se fa parte dell'archivio, oppure no",
        "archivioBook":"Archivio storico",
        "cerca_ordini":"Cerca ordini",
        "cerca_abbonamenti":"Cerca abbonamenti",
        "cerca_coupon":"Cerca coupon",
        "abbonamenti_italia":"Abbonamenti",
        "indirizzo_diverso_fatturazione":"L'indirizzo è diverso da quello di fatturazione ?",
        "nome_cognome_ragione_sociale":"Nome e Cognome / Ragione Sociale",
        "acquisto_singolo":"Acquisto singolo",
        "require_nome_cognome_ragionesociale":"Devi indicare il nome cognome o la ragione sociale per l'indirizzo di spedizione",
        "required_stato":"Devi indicare lo stato",
        "indirizzo_di_spedizione":"Indirizzo di spedizione",
        "digitale_cartaceo_sing":"cartaceo e digitale",
        "digitale_cartaceo_plur":"cartacee e digitali",
        "archivio_data":"Archivio storico 1986-2023",
        "newsletter_num":"50 numeri di MicroMega+",
        "abbonamenti_europa":"Europa",
        "abbonamenti_mondo":"Mondo",
        "digitali":"digitali",
        "cartacee":"cartacee",
        "edizioni_anno_corrente":"Edizioni anno corrente (2024)",
        "edizioni_in_archivio":"Edizioni in archivio dal 1986 al 2023",
        "acquista_cartacea":"Acquista cartacea",
        "acquista_digitale":"Acquista digitale",
        "importo_spedizione":"Importo spedizione",
        "seleziona_cartaceo":"Seleziona cartaceo",
        "stato_sped":"Stato",
        "yes":"SI",
        "ordinamento":"Ordinamento",
        "other_user_order":"Altri ordini",
        "abbonamento_attivo":"Abbonamento attivo",
        "i_miei_acquisti":"I miei acquisti",
        "negozio":"Negozio",
        "cartacea":"Cartacea",
        "italia": "Italia",
        "austria": "Austria",
        "belgio": "Belgio",
        "bulgaria": "Bulgaria",
        "cipro": "Cipro",
        "croazia": "Croazia",
        "danimarca": "Danimarca",
        "estonia": "Estonia",
        "finlandia": "Finlandia",
        "francia": "Francia",
        "germania": "Germania",
        "grecia": "Grecia",
        "irlanda": "Irlanda",
        "inghilterra": "Inghilterra",
        "lettonia": "Lettonia",
        "lituania": "Lituania",
        "lussemburgo": "Lussemburgo",
        "malta": "Malta",
        "paesi_bassi": "Paesi Bassi",
        "polonia": "Polonia",
        "portogallo": "Portogallo",
        "romania": "Romania",
        "slovacchia": "Slovacchia",
        "slovenia": "Slovenia",
        "spagna": "Spagna",
        "svezia": "Svezia",
        "ungheria": "Ungheria",
        "stati_uniti": "Stati Uniti",
        "canada": "Canada",
        "argentina": "Argentina",
        "brasile": "Brasile",
        "venezuela": "Venezuela",
        "israele": "Israele",
        "turchia": "Turchia",
        "armenia": "Armenia",
        "azerbaigian": "Azerbaigian",
        "bielorussia": "Bielorussia",
        "georgia": "Georgia",
        "kazakistan": "Kazakistan",
        "kirghizistan": "Kirghizstan",
        "moldavia": "Moldavia",
        "russia": "Russia",
        "tagikistan": "Tagikistan",
        "turkmenistan": "Turkmenistan",
        "ucraina": "Ucraina",
        "uzbekistan": "Uzbekistan",
        "australia": "Australia",
        "nuova_zelanda": "Nuova Zelanda",
        "giappone": "Giappone",
        "cina": "Cina",
        "india": "India",
        "corea_del_sud": "Corea del Sud",
        "sud_africa": "Sud Africa",
        "egitto": "Egitto",
        "marocco": "Marocco",
        "kenia": "Kenya",
        "nigeria": "Nigeria",
        "messico": "Messico",
        "colombia": "Colombia",
        "perù": "Perù",
        "cile": "Cile",
        "ecuador": "Ecuador",
        "arretrati_anno":"Arretrati dall'anno 2024",
        "numero_attuale":"Numero attuale",
        "acquisto_andato_a_buon_fine":"Acquisto concluso con successo",
        "subTitleAcquisto":"Tra 5 secondi verrà riportato, in automatico alla pagina del negozio.",
        "indirizzo_fatturazione":"Indirizzo di fatturazione",
        "guest":"Guest",
        "acquisti":"Acquisti",
        "info_abbonamento_digitale_primonumero":"L'abbonamento digitale include il numero attualmente in vendita.",
        "info_abbonamento_cartaceo_primonumero":"Per ragioni logistiche l'abbonamento cartaceo partirà dal primo numero disponibile per la spedizione.",
        "order":"Ordinamento",
        "scopri_micromega_plus": "Scopri MicroMega+",
        "download_lista_utenti":"Download lista utenti",
        "problemi_nella_verifica_del_3d_secured":"Problemi nella verifica del 3d secured,riprova",
        "disabilita_cartaceo":"Disabilita cartaceo",
        "abilita_cartaceo":"Abilita cartaceo",
        "disabilita_digitale":"Disabilita digitale",
        "abilita_digitale":"Abilita digitale",
        "preSales":"Prevendita",
        "acquista_prevendita":"Acquista cartaceo in prevendita",
        "preSales_required":"Il campo prevendita è obbligatorio",
        "newsletter_text":"Vuoi rimanere regolarmente aggiornato sui nuovi articoli di MicroMega?",
        "download_abbonamenti":"Download abbonamenti",
        "download_ordini":"Download ordini",
        "testo_primo_numero_abbonamento_cartaceo":"Gentile lettore, ti informiamo che per ragioni tecniche legate alle tempistiche delle spedizioni, l’abbonamento che stai sottoscrivendo non comprende il numero attualmente in vendita e partirà dal primo numero utile.",
        "data_fine":"Data fine",
        "testo_digitale_no_download":"Gentile lettore, sottoscrivendo l’abbonamento digitale le copie della rivista saranno disponibili per te online all’interno del tuo account fino a che l’abbonamento sarà attivo. Al momento non è possibile scaricare singole copie digitali sul tuo computer.",
        "numeri_prevendita":"Prevendite",
        "prevendita":"Prevendita",
        "testo_digitale_no_download_singola":"Gentile lettore, acquistando la singola copia della rivista, potrai usufruirne all’interno del tuo account fino a che l’abbonamento sarà attivo, in modalità online. Al momento non è possibile scaricare la copia digitale sul tuo computer. Per ulteriori informazioni consulta le FAQ",
        "testo_abbonamenti_extra_italia":"I prezzi indicati per prodotti cartacei si riferiscono a spedizioni in Italia. Per spedizioni all'estero scrivere a: info@micromega.net",
        "donazioni":"Donazioni",
        "abbonamento_europa":"Abbonamento Europa",
        "abbonamento_resto_del_mondo": "Abbonamento resto del mondo",
        "abbonamento_digitale": "Abbonamento digitale",
        "svizzera":"Svizzera"
    }
  },
  it: {
    translation: {
        'registrati': "Registrati",
        'password_dimenticata': "Password dimenticata?",
        'accedi': "Accedi",
        'subtitleLogin': "Utilizza le tue credenziali per accedere alla piattaforma",
        'email': "E-mail",
        'password': "Password",
        "valid_email": "Inserisci una mail valida",
        "required_email": 'L\'indirizzo email è obbligatorio',
        "required_password": 'La password è obbligatoria',
        'subtitleRegister': "Registrandoti a MicroMega potrai acquistare prodotti,abbonamenti ed accedere a contenuti esclusivi",
        "already_registered": "Sei già registrato?",
        "required_name": "Il nome è obbligatorio",
        "privacy_required": "Il campo privacy deve essere accettato.",
        "ho_letto_e_accetto": "Ho letto ed accetto",
        "termini_condizioni": "Termini e condizioni",
        "name": "Nome",
        "cognome": "Cognome",
        "sesso": "Sesso",
        "data_nascita": "Data di nascita",
        "cellulare": "Cellulare",
        "required_cognome": "Il campo cognome è richiesto",
        "required_cellulare": "Il campo cellulare è richiesto",
        "maschio": "Maschio",
        "femmina": "Femmina",
        "altro": "Altro",
        "contact_us": "Contattaci",
        "contact": "Contatti",
        "completa_il_form_di_seguito": "Completa il form di seguito",
        "parla_esperto": "Parla con un esperto",
        "subTitleContact": "Per qualsiasi domanda, dubbio approfondimenti in merito a come funziona il servizio abbonamenti, acquisti delle riviste e dei vari prodotti puoi contattarci,usando il form a fianco oppure via email: info@micromega.net",
        "fullname": "Nome Cognome",
        "azienda": "Azienda",
        "messaggio": "Messaggio",
        "invia": "Invia",
        "scrivendoci_tramite_il_form": "Scrivendoci, stai accettando",
        "e": "and",
        "oggetto": "Oggetto",
        "richiesta_informazioni": "Richiesta informazioni",
        "richiesta_amministrativa": "Richiesta amministrativa",
        "informazioni_privacy": "Informazioni privacy",
        "segnala_un_problema": "Segnala un problema",
        "titleHomeHero": "Scopri il mondo MicroMega",
        "subTitleHomeHero": "Da 35 anni MicroMega è al servizio delle grandi battaglie civili. Puoi ricevere la rivista nel suo caratteristico formato a libro comodamente a casa o leggerla in formato digitale.",
        "subsubTitleHomeHero":"Scegli l'offerta più adatta a te",
        "recupera_password": "Recupera password",
        "required_code": "Il codice è obbligatorio",
        "recuperaPasswordSubtitle": "Utilizza il seguente modulo per recuperare la password, riceverai via email un codice da inserire nel prossimo step insieme alla nuova password.",
        "non_hai_ricevuto_il_codice": "Non hai ricevuto il codice?",
        "password_reset": "Reset password",
        "cambia_password_con_il_codice": "Cambia password con il codice ricevuto via email.",
        "deve_avere_almeno_7_carattere": "Deve avere almeno 7 caratteri.",
        "codice_verifica": "Codice di verfica",
        "conferma_password": "Conferma password",
        "password_recovery": "Password recovery",
        "conferma": "Conferma",
        "overview": "Home",
        "profile": "Profilo",
        "i_miei_ordini": "I miei ordini",
        "prodotti": "Prodotti",
        "abbonamenti": "Abbonamenti",
        "utenti": "Utenti",
        "acquista": "Acquista",
        "currency_symbol": "€",
        "digitale": "Digitale",
        "cartaceo": "Cartaceo",
        "archivio": "Archivio",
        "indietro": "Indietro",
        "complete_order": "Completa ordine",
        "informazioni_pagamentos": "Informazioni per il pagamento",
        "dashboard": "Home",
        "profilo": "Profilo",
        "subtotale": "Subtotale",
        "costo_spedizione": "Costo di spedizione",
        "riepilogo_oridne": "Riepilogo ordine",
        "totale": "Totale",
        "dati_fatturazione": "Dati fatturazione",
        "required_subject": "L'oggetto è obbligatorio",
        "required_testo": "Il testo è obbligatorio",
        "problemi_invio_mail": "Problemi nell'invio della mail, riprova",
        "concludi_ordine": "Concludi ordine",
        "privato": "Privato",
        "privato_o_azienda": "Sei un privato o azienda?",
        "ragioneSociale": "Ragione Sociale",
        "codiceFiscalePIVA": "Codice fiscale/P.iva",
        "citta": "Città",
        "provincia": "Provincia",
        "codiceDest": "Codice destinatario",
        "pec": "Pec",
        "cap": "Cap",
        "indirizzo": "Indirizzo",
        "acquisto_sicuro": "Acquisto sicuro",
        "info_tuo_acquisto": "Il tuo acquisto verrà eseguito tramite braintree con certificazione ssl",
        "creditCard_debitcart": "Carta di credito / Debito",
        "paypal": "Paypal",
        "metodi_pagamento": "Metodi di pagamento",
        "telefono": "Telefono",
        "nome_sulla_carta": "Nome sulla carta",
        "salva": "Salva",
        "il_tuo_abbonamento": "Il tuo abbonamento",
        "profilo_aggiornato": "Profilo aggiornato",
        "editPassword": "Modifica password",
        "cambia": "Cambia",
        "currentPassword": "Password corrente",
        "confermaPassword": "Conferma password",
        "nuovaPassword": "Nuova password",
        "password_aggiornata": "Password aggiornata con successo!",
        "leduePasswordNonCoincidono": "Le due password non coincidono",
        "required_password_old": 'La vecchia password è obbligatoria',
        "required_password_confirm": 'La conferma della password è obbligatoria',
        "faq": "F.A.Q",
        "subTitleFaq": "Qui puoi trovare le risposte alle domande più frequenti.",
        "torna_indietro": "Torna indietro",
        "401_subtitle": "Stai provando ad accedere ad una pagina che richiede l'autenticazione, prosegui la navigazione usando la barra in alto o torna indietro.",
        "401_subtitle_admin": "Non hai i permessi sufficienti per visualizzare questa pagina.",
        "401_title": "Errore 401 non sei autorizzato",
        "bonifico_bancario": "Bonifico bancario",
        "erroreServer": "Errore server",
        "500_title": "Errore 500 problema interno del server",
        "500_subtitle": "La richiesta che hai inviato, non è andata a buon fine, riprova o contatta l'assistenza del servizio tecnico, descrivendo il problema.",
        "non_trovato": "Non trovato",
        "404_title": "Errore 404 risorsa cercata non trovata",
        "404_subtitle": "Il percorso ricerca non è stato trovato, controlla di aver digitato correttamente il link, riprova.",
        "logout": "Logout",
        "riepilogo_abbonamento": "Riepilogo abbonamento",
        "vai": "Vai",
        "importo": "Importo",
        "scadenza": "Scadenza",
        "metodo": "Metodo",
        "stato": "Stato",
        "ordine": "Ordine",
        "dettaglio_ordine": "Dettaglio ordine",
        "ordini": "Ordini",
        "numero": "Numero",
        "cliente": "Cliente",
        "data": "Data",
        "aggiungi_abbonamento": "Aggiungi abbonamento",
        "nome": "Nome",
        "descrizione": "Descrizione",
        "tipologia": "Tipologia",
        "prezzo": "Prezzo",
        "resetDispositivi": "Reset dispositivi",
        "dispositivi": "Dispositivi",
        "non_sono_riuscito_logout": "Non sono riuscito a fare il logout",
        "uuid": "Identificativo device",
        "os": "Sistema operativo",
        "push_notification": "Codice push notifications",
        "cancellato": "Cancellato",
        "standby": "Sospeso",
        "attivo": "Attivo",
        "non_hai_abbonamenti_attivi": "Non hai abbonamenti attivi",
        "non_ha_abbonamenti_attivi": "Non ha abbonamenti attivi",
        "aggiungi_utente": "Aggiungi utente",
        "lista_utenti": "Lista utenti",
        "nome_cognome": "Nome Cognome",
        "id": "ID",
        "in_attesa": "In attesa",
        "ordina_per": "Ordina per",
        "problemi_nel_caricamento": "Problemi nel caricamento",
        "utente": "Utente",
        "device_resettati": "Device reimpostati",
        "registralo": "Registralo",
        "utente_creato": "Utente creato con successo",
        "problema_cambio_password": "Problema cambio password",
        "blocca": "Blocca",
        "attiva": "Attiva",
        "operazione_non_riuscita_riprova": "Operazione non riuscita, riprova",
        "cancella": "Cancella",
        "non_ha_device_registrati": "Non ha devices registrati",
        "assegna_abbonamento": "Assegna abbonamento",
        "subTitleAssegnaAbbonamento": "Seleziona uno degli abbonamenti disponibili, per assegnarlo gratuitamente al cliente",
        "annulla": "Annulla",
        "abbonamento_assegnato": "Abbonamento assegnato con successo",
        "seleziona": "Seleziona",
        "attiva_abbonamento": "Attiva abbonamento",
        "sospendi_abbonamento": "Sospendi abbonamento",
        "aggiornamento_ok": "Aggiornamento andato a buon fine",
        "modifica": "Modifica",
        "aggiungi_tipologia_abbonamento": "Aggiungi tipologia abbonamento",
        "required_description": "Il campo descrizione è obbligatorio",
        "required_duration": "Il campo durata è obbligatorio",
        "required_price": "Il campo prezzo è obbligatorio",
        "cartaceo_required": "Devi selezionare se è cartaceo oppure no",
        "archivio_required": "Devi selezionare se da accesso all'archivio oppure no",
        "aggiungi": "Aggiungi",
        "tipologia_abbonamento_creato": "Tipologia abbonamento creato con successo.",
        "duration": "Durata",
        "price": "Prezzo",
        "tipologie_abbonamenti": "Tipologie abbonamenti",
        "elimina": "Elimina",
        "stato_abbonamento": "Stato abbonamento",
        "aggiorna": "Aggiorna",
        "tipologia_abbonamento": "Tipologia abbonamento",
        "modifica_tipologia_abbonamento": "Modifica tipologia abbonamento",
        "abbonamenti_acquistati": "Abbonamenti acquistati",
        "dettaglio_acquisto_abbonamento": "Dettaglio acquisto abbonamento",
        "startDate": "Data inizio",
        "metodo_di_pagamento": "Metodo di pagamento",
        "in_attivazione": "In attivazione",
        "registratoInData": "Registrato in data",
        "nomeCognome": "Nome e Cognome",
        "stato_utente": "Stato utente",
        "is_azienda": "E' un'azienda",
        "coupon": "Coupon",
        "applica_coupon": "Applica il coupon",
        "nuovo": "Nuovo",
        "codice": "Codice",
        "percentuale": "Percentuale",
        "coupon_creato": "Coupon creato con successo",
        "aggiungi_coupon": "Aggiungi coupon",
        "required_amount": "L'importo è obbligatorio",
        "required_percentage": "Devi indicare se si tratta di percentuale oppure no",
        "coupon_detail": "Dettaglio coupon",
        "aggiornato": "Aggiornato con successo.",
        "modifica_coupon": "Modifica coupon",
        "sospendi": "Sospendi",
        "stato_coupon": "Stato coupon",
        "rimuovi_coupon": "Rimuovi coupon",
        "cart_is_empty": "Il carrello è vuoto, torna indietro e seleziona un prodotto o un abbonamento per procedere.",
        "errore_500": "Errore 500",
        "desideri_la_fattura": "Desideri la fattura ?",
        "si": "Sì",
        "no": "No",
        "fattura": "Fattura",
        "creditCard": "Carta di credito",
        "bonifico": "Bonifico bancario",
        "free_admin": "Assegnato da amministratore",
        "free": "Gratuito",
        "coupon_non_attivo": "Coupon non attivo",
        "data_inizio": "Data inizio",
        "nome_device": "Nome dispositivo",
        "identificativo": "Identificativo",
        "no_name": "Nessun nome",
        "aggiungi_prodotto": "Aggiungi prodotto",
        "cerca_prodotti": "Cerca prodotto...",
        "required_title": "Il titolo è richiesto",
        "required_isbn": "Il codice isbn è richiesto",
        "subscriptionIncluded_required": "Devi indicare se è incluso o no nell'abbonamento",
        "prodotto_creato": "Prodotto creato con successo",
        "isbn": "Isbn",
        "subscriptionIncluded": "Il prodotto è incluso nell'abbonamento?",
        "title": "Titolo",
        "amount": "Importo",
        "select_immagine_di_copertina": "Seleziona l'immagine del prodotto",
        "seleziona_il_libro_digitale": "Seleziona il libro digitale da caricare",
        "libro_digitale": "Libro digitale",
        "rimuovi": "Rimuovi",
        "seleziona_file": "Seleziona file",
        "leggi": "Leggi",
        "nome_file": "Nome file",
        "dimensioni_file": "Dimensioni file",
        "prodotto_creato_con_problemi": "Il prodotto è stato creato con successo, ma l'immagine o l'allegato potrebbero non essere stati caricati correttamente, verifica",
        "modifica_prodotto": "Modifica prodotto",
        "problemi_elaborazione": "Problemi nell'elaborazione, riprova",
        "prodotto_aggiornato": "Prodotto aggiornato",
        "nessun_device_registrato": "Non ci sono dispositivi registrati.",
        "cerca_customer": "Cerca utenti...",
        "di": "di",
        "righe_per_pagine": "Righe per pagina",
        "prodotto": "Prodotto",
        "viewer": "Lettore",
        "disabilita": "Disabilita",
        "abilita": "Abilita",
        "metodo_pagamento_non_riconosciuto": "Il metodo di pagamento non è stato riconosciuto.",
        "pagamento_non_andato_a_buonfine": "Il pagamento non è andato a buon fine, riprova.",
        "problemi_aggiornamento_profilo": "Problemi aggiornamenti profilo",
        "richiedi_cancellazione_account": "Richiedi cancellazione account",
        "informazioni_account": "Informazioni account",
        "account_creato": "Profile creato il:",
        "ultimo_aggiornamento": "Ultimo aggiornamento il:",
        "assegnaProdotto": "Assegna prodotto",
        "subTitleAssegnaProdotto": "Seleziona uno dei prodotti disponibili, da assegnare gratuitamente all'utente scelto.",
        "prodotto_assegnato": "Prodotto assegnato",
        "datiIbanBonifico": "\nBENEFICIARIO: Micromega Edizioni impresa sociale SRL \nIBAN:  IT18N0623003201000064268523\nP.Iva/cod.fiscale - 15971151004\nCAUSALE: Indicare nome, cognome e indirizzo",
        "required_codiceFiscale": 'Il codice fiscale o la p.iva sono obbligatori',
        "required_ragioneSociale": 'La ragione sociale è obbligatorio se sei un\'azienda',
        "required_codiceDest": "Il codice destinatario è obbligatorio",
        "required_pec": "La pec è obbligatoria",
        "required_indirizzo": "L'indirizzo è obbligatorio",
        "required_citta": "La città è obbligatoria",
        "required_provincia": "La provincia è obbligatoria",
        "required_cap": "Il cap è obbligatorio",
        "required_telefono": "Il numero di telefono è obbligatorio",
        "dataScadenza": "Data di scadenza",
        "codiceSicurezza": "Codice di sicurezza",
        "cardNumber": "Numero della carta",
        "required_cardOwner": "Il nome dell'intestario della carta è obbligatorio",
        "required_cardNumber": "Il numero della carta è obbligatorio",
        "required_cardExpirationDate": "La scadenza della carta è obbligatoria",
        "required_cardSecurityCode": "Il codice di sicurezza della carta è obbligatorio",
        "idTransaction":"Id transazione",
        "pubblicita":"Pubblicità",
        "pubblicitaSubTitle":"Vuoi promuovere la tua attività su MicroMega? Scopri come",
        "acquistaSubTitle":"Scopri tutte le opzioni per acquistare e/o abbonarti a MicroMega",
        "donazioneTitle":"Vuoi fare una donazione a MicroMega?",
        "donazioneSubTitle":"Scopri come sostenerci",
        "micromegaplus":"MicroMega+",
        "micromegaplusSubTitle":"Leggi tutti i contenuti esclusivi della nostra newsletter!",
        "newsletter":"Newsletter",
        "spedizioni_nazionali":"Spedizioni nazionali",
        "spedizioni_europee":"Spedizioni europee",
        "spedizioni_internazionali":"Spedizioni internazionali",
        "digitale_required":"Devi indicare se è di tipo digitale o no",
        "newsletter_required":"Devi indicare se è compresa la newsletter o no",
        "spedizione_required":"Devi indicare la tipologia di spedizione",
        "nessuna_spedizione":"Nessuna spedizione",
        "spedizione":"Spedizione",
        "europa":"Europa",
        "mondo":"Mondo",
        "archivio_required_book": "Devi selezionare se fa parte dell'archivio, oppure no",
        "archivioBook":"Archivio storico",
        "cerca_ordini":"Cerca ordini",
        "cerca_abbonamenti":"Cerca abbonamenti",
        "cerca_coupon":"Cerca coupon",
        "abbonamenti_italia":"Abbonamenti",
        "indirizzo_diverso_fatturazione":"L'indirizzo è diverso da quello di fatturazione ?",
        "nome_cognome_ragione_sociale":"Nome e Cognome / Ragione Sociale",
        "acquisto_singolo":"Acquisto singolo",
        "require_nome_cognome_ragionesociale":"Devi indicare il nome cognome o la ragione sociale per l'indirizzo di spedizione",
        "required_stato":"Devi indicare lo stato",
        "indirizzo_di_spedizione":"Indirizzo di spedizione",
        "digitale_cartaceo_sing":"cartaceo e digitale",
        "digitale_cartaceo_plur":"cartacee e digitali",
        "archivio_data":"Archivio storico 1986-2023",
        "newsletter_num":"50 numeri di MicroMega+",
        "abbonamenti_europa":"Europa",
        "abbonamenti_mondo":"Mondo",
        "digitali":"digitali",
        "cartacee":"cartacee",
        "edizioni_anno_corrente":"Edizioni anno corrente (2024)",
        "edizioni_in_archivio":"Edizioni in archivio dal 1986 al 2023",
        "acquista_cartacea":"Acquista cartacea",
        "acquista_digitale":"Acquista digitale",
        "importo_spedizione":"Importo spedizione",
        "seleziona_cartaceo":"Seleziona cartaceo",
        "stato_sped":"Stato",
        "yes":"SI",
        "ordinamento":"Ordinamento",
        "other_user_order":"Altri ordini",
        "abbonamento_attivo":"Abbonamento attivo",
        "i_miei_acquisti":"I miei acquisti",
        "negozio":"Negozio",
        "cartacea":"Cartacea",
        "italia": "Italia",
        "austria": "Austria",
        "belgio": "Belgio",
        "bulgaria": "Bulgaria",
        "cipro": "Cipro",
        "croazia": "Croazia",
        "danimarca": "Danimarca",
        "estonia": "Estonia",
        "finlandia": "Finlandia",
        "francia": "Francia",
        "germania": "Germania",
        "grecia": "Grecia",
        "irlanda": "Irlanda",
        "inghilterra": "Inghilterra",
        "lettonia": "Lettonia",
        "lituania": "Lituania",
        "lussemburgo": "Lussemburgo",
        "malta": "Malta",
        "paesi_bassi": "Paesi Bassi",
        "polonia": "Polonia",
        "portogallo": "Portogallo",
        "romania": "Romania",
        "slovacchia": "Slovacchia",
        "slovenia": "Slovenia",
        "spagna": "Spagna",
        "svezia": "Svezia",
        "ungheria": "Ungheria",
        "stati_uniti": "Stati Uniti",
        "canada": "Canada",
        "argentina": "Argentina",
        "brasile": "Brasile",
        "venezuela": "Venezuela",
        "israele": "Israele",
        "turchia": "Turchia",
        "armenia": "Armenia",
        "azerbaigian": "Azerbaigian",
        "bielorussia": "Bielorussia",
        "georgia": "Georgia",
        "kazakistan": "Kazakistan",
        "kirghizistan": "Kirghizstan",
        "moldavia": "Moldavia",
        "russia": "Russia",
        "tagikistan": "Tagikistan",
        "turkmenistan": "Turkmenistan",
        "ucraina": "Ucraina",
        "uzbekistan": "Uzbekistan",
        "australia": "Australia",
        "nuova_zelanda": "Nuova Zelanda",
        "giappone": "Giappone",
        "cina": "Cina",
        "india": "India",
        "corea_del_sud": "Corea del Sud",
        "sud_africa": "Sud Africa",
        "egitto": "Egitto",
        "marocco": "Marocco",
        "kenia": "Kenya",
        "nigeria": "Nigeria",
        "messico": "Messico",
        "colombia": "Colombia",
        "perù": "Perù",
        "cile": "Cile",
        "ecuador": "Ecuador",
        "arretrati_anno":"Arretrati dall'anno 2024",
        "numero_attuale":"Numero attuale",
        "acquisto_andato_a_buon_fine":"Acquisto concluso con successo",
        "subTitleAcquisto":"Tra 5 secondi verrà riportato, in automatico alla pagina del negozio.",
        "indirizzo_fatturazione":"Indirizzo di fatturazione",
        "guest":"Guest",
        "acquisti":"Acquisti",
        "info_abbonamento_digitale_primonumero":"L'abbonamento digitale include il numero attualmente in vendita.",
        "info_abbonamento_cartaceo_primonumero":"Per ragioni logistiche l'abbonamento cartaceo partirà dal primo numero disponibile per la spedizione.",
        "order":"Ordinamento",
        "scopri_micromega_plus": "Scopri MicroMega+",
        "download_lista_utenti":"Download lista utenti",
        "problemi_nella_verifica_del_3d_secured":"Problemi nella verifica del 3d secured,riprova",
        "disabilita_cartaceo":"Disabilita cartaceo",
        "abilita_cartaceo":"Abilita cartaceo",
        "disabilita_digitale":"Disabilita digitale",
        "abilita_digitale":"Abilita digitale",
        "preSales":"Prevendita",
        "acquista_prevendita":"Acquista cartaceo in prevendita",
        "preSales_required":"Il campo prevendita è obbligatorio",
        "newsletter_text":"Vuoi rimanere regolarmente aggiornato sui nuovi articoli di MicroMega?",
        "download_abbonamenti":"Download abbonamenti",
        "download_ordini":"Download ordini",
        "testo_primo_numero_abbonamento_cartaceo":"Gentile lettore, ti informiamo che per ragioni tecniche legate alle tempistiche delle spedizioni, l’abbonamento che stai sottoscrivendo non comprende il numero attualmente in vendita e partirà dal primo numero utile.",
        "data_fine":"Data fine",
        "testo_digitale_no_download":"Gentile lettore, sottoscrivendo l’abbonamento digitale le copie della rivista saranno disponibili per te online all’interno del tuo account fino a che l’abbonamento sarà attivo. Al momento non è possibile scaricare le copie digitale sul tuo computer.",
        "numeri_prevendita":"Prevendite",
        "prevendita":"Prevendita",
        "testo_digitale_no_download_singola":"Gentile lettore, acquistando la singola copia della rivista, potrai usufruirne all’interno del tuo account fino a che l’abbonamento sarà attivo, in modalità online. Al momento non è possibile scaricare la copia digitale sul tuo computer. Per ulteriori informazioni consulta le FAQ",
        "testo_abbonamenti_extra_italia":"I prezzi indicati per prodotti cartacei si riferiscono a spedizioni in Italia. Per spedizioni all'estero scrivere a: info@micromega.net",
        "donazioni":"Donazioni",
        "abbonamento_europa":"Abbonamento Europa",
        "abbonamento_resto_del_mondo": "Abbonamento resto del mondo",
        "abbonamento_digitale": "Abbonamento digitale",
        "svizzera":"Svizzera"
    }
  }
};
i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'it',
    fallbackLng: 'it',
    interpolation: {
      escapeValue: false
    }
  });
