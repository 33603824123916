import { useEffect } from 'react';
import type { FC } from 'react';
import { useRoutes } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import {DeviceUUID} from 'device-uuid'
import {Alert, CssBaseline, makeStyles, Snackbar, ThemeProvider} from '@material-ui/core';
import './i18n';
import SplashScreen from './components/SplashScreen';
import useAuth from './hooks/useAuth';
import useScrollReset from './hooks/useScrollReset';
import useSettings from './hooks/useSettings';
import routes from './routes';
import { createCustomTheme } from './theme';
import {AuthContextHelper} from "./contexts/JWTContext";


const App: FC = () => {
  const content = useRoutes(routes);
  const { settings } = useSettings();
  const auth = useAuth();
  AuthContextHelper.setAuthContext(auth)
  useScrollReset();

  useEffect(() => {}, []);
  const useStyles = makeStyles((theme) => ({
    warning: {
      backgroundColor: 'yellow',
      fontWeight: 'bold',
      '& .MuiAlert-message': {
        color: 'black', // Cambia il colore del testo qui
      },
      '& .MuiSvgIcon-root': {
        fill: 'black', // Cambia il colore dell'icona qui
      },
    },
  }));
  const theme = createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme
  });
  const classes = useStyles();

  //setup uuid
  if(!localStorage.getItem("uuid")){
    const du = new DeviceUUID().parse();
    const dua = [
      du.language, du.platform, du.os, du.cpuCores, du.isAuthoritative, du.silkAccelerated, du.isKindleFire, du.isDesktop, du.isMobile,
      du.isTablet, du.isWindows, du.isLinux, du.isLinux64, du.isMac, du.isiPad, du.isiPhone, du.isiPod, du.isSmartTV,
      du.pixelDepth, du.isTouchScreen];
    localStorage.setItem("uuid",du.hashMD5(dua.join(':')))
  }
  return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Toaster position="top-center" />
        <Snackbar open={true} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Alert className={classes.warning} variant="filled" severity="warning">
            Questo sito non è più attivo. Per abbonarti a MicroMega o leggere i contenuti a cui sei già abbonato registrati sul nuovo sito <a href="https://www.micromega.net">micromega.net</a>. Se sei già abbonato il tuo abbonamento continuerà a essere valido, ma è necessario registrarsi sulla nuova piattaforma per continuare ad accedere ai contenuti
          </Alert>
        </Snackbar>
        {auth.isInitialized ? content : <SplashScreen />}
      </ThemeProvider>
  );
};

export default App;
