export const THEMES = {
  LIGHT: 'LIGHT',
};

export const LINK_PRIVACY = "https://www.iubenda.com/privacy-policy/87466855"

export const LINK_COOKIE_POLICY = "https://www.iubenda.com/privacy-policy/87466855/cookie-policy"

export const LINK_TERMS = "https://www.iubenda.com/termini-e-condizioni/87466855"

export const URL_AMAZON = "https://amzn.to/3KcJyJy";
