import { useCallback, useEffect, useState } from 'react';
import type { FC } from 'react';
import {Link as RouterLink, useNavigate} from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
    Box,
    Breadcrumbs,
    Button, Card,
    Container,
    Grid,
    Link,
    Typography
} from '@material-ui/core';
import useSettings from '../../../hooks/useSettings';
import ChevronRightIcon from '../../../res/icons/ChevronRight';
import i18n from "i18next";
import {useTheme} from "@material-ui/core/styles";
import useAuth from "../../../hooks/useAuth";
import {SubscriptionController} from "../../../api/controller/subscription.controller";
import {Subscription} from "../../../model/types/subscription";
import SubscriptionsPurchasedList
    from "../../../components/dashboard/subscriptions/purchased/SubscriptionsPurchasedTable";
import PlusIcon from "../../../res/icons/Plus";
import {User} from "../../../model/types/user";
import DownloadIcon from "../../../res/icons/Download";
import {JSONToCSVConvertor, JSONToCSVSubscriptionConvertor} from "../../../utils/genericUtils";

const SubscriptionPurchasedList: FC = () => {
  const theme = useTheme();
  const {user} = useAuth()
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [subscriptions, setSubscriptions] = useState<Array<Subscription>>(Array());
  const navigate = useNavigate();
  const { settings } = useSettings();

  useEffect(() => {
    (
        async () => {
          const responseAllSubScription = await SubscriptionController.findAll()
          setSubscriptions(responseAllSubScription.data)
          setIsLoading(false);
        }
    )();
  }, [theme.palette.mode]);

  return (
    <>
      <Helmet>
        <title>{i18n.t("abbonamenti_acquistati")} | Micromega</title>
      </Helmet>

        <Box
            sx={{
                backgroundColor: 'background.default',
                minHeight: '100%',
                py: 8
            }}
        >
            <Container maxWidth={settings.compact ? 'xl' : false}>
                <Grid
                    container
                    justifyContent="space-between"
                    spacing={3}
                >
                    <Grid item>
                        <Breadcrumbs
                            aria-label="breadcrumb"
                            separator={<ChevronRightIcon fontSize="small" />}
                            sx={{ mt: 1 }}
                        >
                            <Link
                                color="textPrimary"
                                component={RouterLink}
                                to="/dashboard"
                                variant="subtitle2"
                            >
                                {i18n.t("dashboard")}
                            </Link>
                            <Typography
                                color="textSecondary"
                                variant="subtitle2"
                            >
                                {i18n.t("abbonamenti_acquistati")}
                            </Typography>
                        </Breadcrumbs>
                    </Grid>
                    <Grid item>
                        <Box sx={{ m: -1 }}>
                            {User.isAdmin(user) ? (
                                <Button
                                    color="primary"
                                    startIcon={<DownloadIcon fontSize="small" />}
                                    sx={{ m: 1 }}
                                    variant="contained"
                                    onClick={()=>{
                                        let json = JSON.stringify(subscriptions)
                                        console.log("json subscriptions",json);
                                        JSONToCSVSubscriptionConvertor(json,"abbonamenti_micromega");
                                    }}
                                >
                                    {i18n.t("download_abbonamenti")}
                                </Button>
                            ):null}
                        </Box>
                    </Grid>
                </Grid>
                <Box sx={{ mt: 3 }}>
                    <SubscriptionsPurchasedList subscriptions={subscriptions} />
                </Box>
            </Container>
        </Box>
    </>
  );
};

export default SubscriptionPurchasedList;
