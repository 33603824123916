import {experimentalStyled} from "@material-ui/core/styles";
import {checkVAT, countries} from "jsvat";
import CodiceFiscale from "codice-fiscale-js";
import i18n from "i18next";
import {DateUtils} from "./dateUtils";
import {format} from "date-fns";

export class GenericUtility{
    public static vatCfChecker(vatCf: string): boolean{
        try{
            let cf = new CodiceFiscale(vatCf)
            if(cf.isValid()){
                console.log("CF VALID")
                return true
            }
        }catch(e){
        }
        let vatTmp = vatCf;
        if(this.isLeadingDigit(vatTmp.charAt(0)) || this.isLeadingDigit(vatTmp.charAt(1))){
            vatTmp = "IT"+vatTmp;
        }
        console.log("VAT TMP",vatTmp);
        const resp = checkVAT(vatTmp, countries);
        return resp.isValid;
    }
    public static isLeadingDigit(value: string): boolean{
        let c = value.charAt(0);
        return (c >= '0' && c <= '9');
    }
}
export const toBase64 = (file: File): Promise<ArrayBuffer | string> => new Promise((
    resolve,
    reject
) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
});


export const BlogPostCardMediaWrapper = experimentalStyled('div')({
    paddingTop: 'calc(100% * 4 / 4)',
    position: 'relative'
});

export const BlogPostCardMediaWrapperMid = experimentalStyled('div')({
    paddingTop: 'calc(50% * 4 / 4)',
    position: 'relative'
});

export const JSONToCSVConvertor = (JSONData, ReportTitle, ShowLabel) => {
    let index;
    let row;
//If JSONData is not an object then JSON.parse will parse the JSON string in an Object
    const arrData = typeof JSONData !== "object" ? JSON.parse(JSONData) : JSONData;

    let CSV = "";

    //This condition will generate the Label/Header
    if (ShowLabel) {
        row = "";

        //This loop will extract the label from 1st index of on array
        for (index in arrData[0]) {
            //Now convert each value to string and comma-seprated
            row += index + ",";
        }

        row = row.slice(0, -1);

        //append Label row with line break
        CSV += row + "\r\n";
    }

    //1st loop is to extract each row
    for (let i = 0; i < arrData.length; i++) {
        row = "";

        //2nd loop will extract each column and convert it in string comma-seprated
        for (index in arrData[i]) {
            if(arrData[i][index] && arrData[i][index].nome && arrData[i][index].cognome){
                row += '"' + arrData[i][index].nome +" "+arrData[i][index].cognome + " "+arrData[i][index].email + '",'; //TODO FORSE INSERIRE LA MAIL
            }else if(arrData[i][index] && arrData[i][index].description){
                row += '"' + arrData[i][index].description+ '",';
            }else if(arrData[i][index]){
                row += '"' + arrData[i][index] + '",';
            }else{
                row += '"'+'",';
            }
        }

        row.slice(0, row.length - 1);

        //add a line break after each row
        CSV += row + "\r\n";
    }

    if (CSV === "") {
        alert("Invalid data");
        return;
    }

    //Generate a file name
    let fileName = "MyReport_";
    //this will remove the blank-spaces from the title and replace it with an underscore
    fileName += ReportTitle.replace(/ /g, "_");

    //Initialize file format you want csv or xls
    const uri = "data:text/csv;charset=utf-8," + escape(CSV);

    // Now the little tricky part.
    // you can use either>> window.open(uri);
    // but this will not work in some browsers
    // or you will not get the correct file extension

    //this trick will generate a temp <a /> tag
    const link = document.createElement("a");
    link.href = uri;

    //set the visibility hidden so it will not effect on your web-layout
    // @ts-ignore
    link.style = {visibility: "hidden"}
    link.download = fileName + ".csv";

    //this part will append the anchor tag and remove it after automatic click
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export const JSONToCSVSubscriptionConvertor = (JSONData, ReportTitle) => {
    let index;
    let row;
//If JSONData is not an object then JSON.parse will parse the JSON string in an Object
    const arrData = typeof JSONData !== "object" ? JSON.parse(JSONData) : JSONData;

    //let CSV = "Cognome/Ragione Sociale,Nome,Indirizzo spedizione,cap,città,provincia,stato,indirizzo mail,telefono,tipologia,importo,metodo di pagamento,cf,indirizzo fatturazione,cap,città,provincia,stato,codice univoco,pec";
    let CSV = "Cognome/Ragione Sociale;Nome;Indirizzo completo spedizion;Indirizzo spedizione;Cap;Città;Provincia;Stato;Indirizzo mail;Telefono;Tipologia;Data sottoscrizione;Importo;Metodo di pagamento;CF/P.IVA;Indirizzo fatturazione;Cap;Città;Provincia;Stato;Codice univoco;Pec";
    CSV += "\r\n";

    console.log(CSV);
    //This condition will generate the Label/Header

    //1st loop is to extract each row
    for (let i = 0; i < arrData.length; i++) {
        const item = arrData[i];
        const indirizzoSpedCompleto = item.indirizzo
        let splittedACapo;
        let soloIndirizzoSplitted
        let stato_spedizione=""
        let telefono_1 = "";let telefono_2="";
        let indirizzoSpedizioneCompleto = ""
        let cittaSpedizione = ""
        let provinciaSpedizione = ""
        let capSpedizione=""
        let indirizzoSpedizione="";
        let dataAbbonamento = ""

        if(indirizzoSpedCompleto){
            splittedACapo = indirizzoSpedCompleto.split("\n")
            if(splittedACapo){
                if(splittedACapo[1]){
                    const regex = /(.+?) (\d+) ([\w\s]+) ([\w\s]+) (\d+)/;

                    const matches = regex.exec(splittedACapo[1]);
                    if (matches) {
                        indirizzoSpedizione =  matches[1] + " " + matches[2];
                        capSpedizione = matches[5];
                        cittaSpedizione = matches[3];
                        provinciaSpedizione = matches[4];
                    } else {
                        console.log("Nessuna corrispondenza trovata.");
                    }
                    indirizzoSpedizioneCompleto = splittedACapo[1];
                }

                stato_spedizione = splittedACapo[splittedACapo.length-3]
                telefono_1 = splittedACapo[splittedACapo.length-1];
                telefono_2 = splittedACapo[splittedACapo.length-2];
            }
        }

        dataAbbonamento = format(DateUtils.parsedDate(item.createdAt), 'dd/MM/yyyy')

       // row = `${item.user.cognome} ${item.user.ragioneSociale},${item.user.nome}',${indirizzoSpedizioneCompleto},'cap','citta','provincia',${stato_spedizione},${item.user.email},${telefono_1} ${telefono_2},${item.subscriptionType.description},${item.amount},${item.method},${item.user.codiceFiscalePIVA},${item.user.indirizzo},${item.user.cap},${item.user.citta},${item.user.provincia},${item.user.stato},${item.user.codiceDest},${item.user.pec}`;
        row = `${item.user.cognome} ${item.user.ragioneSociale};${item.user.nome};${indirizzoSpedizioneCompleto};${indirizzoSpedizione};${capSpedizione};${cittaSpedizione};${provinciaSpedizione};${stato_spedizione};${item.user.email};${telefono_1} ${telefono_2};${item.subscriptionType.description};${dataAbbonamento};${item.amount};${item.method};${item.user.codiceFiscalePIVA};${item.user.indirizzo};${item.user.cap};${item.user.citta};${item.user.provincia};${item.user.stato};${item.user.codiceDest};${item.user.pec}`;

        row.slice(0, row.length - 1);

        //add a line break after each row
        CSV += row + "\r\n";
    }

    if (CSV === "") {
        alert("Invalid data");
        return;
    }

    //Generate a file name
    let fileName = "MyReport_";
    //this will remove the blank-spaces from the title and replace it with an underscore
    fileName += ReportTitle.replace(/ /g, "_");

    //Initialize file format you want csv or xls
    const uri = "data:text/csv;charset=utf-8," + escape(CSV);

    // Now the little tricky part.
    // you can use either>> window.open(uri);
    // but this will not work in some browsers
    // or you will not get the correct file extension

    //this trick will generate a temp <a /> tag
    const link = document.createElement("a");
    link.href = uri;

    //set the visibility hidden so it will not effect on your web-layout
    // @ts-ignore
    link.style = {visibility: "hidden"}
    link.download = fileName + ".csv";

    //this part will append the anchor tag and remove it after automatic click
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};