import type {FC} from 'react';
import {useCallback, useEffect, useState} from 'react';
import {Link as RouterLink, useNavigate, useParams} from 'react-router-dom';
import {Helmet} from 'react-helmet-async';
import {
    Box,
    Breadcrumbs,
    Button,
    Container,
    Grid,
    Link,
    Typography
} from '@material-ui/core';
import ChevronRightIcon from '../../../res/icons/ChevronRight';
import useSettings from '../../../hooks/useSettings';
import i18n from "i18next";
import toast from "react-hot-toast";
import {Subscription} from "../../../model/types/subscription";
import {SubscriptionController} from "../../../api/controller/subscription.controller";
import {SubscriptionDeleteDTO} from "../../../model/dto/subscription.dto";
import SubscriptionsPurchasedSummary
    from "../../../components/dashboard/subscriptions/purchased/SubscriptionsPurchasedSummary";
import CustomerSummary from "../../../components/dashboard/customer/CustomerSummary";
import ClearIcon from '@material-ui/icons/Clear';
import DoneIcon from '@material-ui/icons/Done';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';

const SubscriptionPurchasedDetail: FC = (props) => {
    const { settings } = useSettings();
    const [subscription, setSubscription] = useState<Subscription | null>(null);
    const params = useParams();
    const navigate = useNavigate()
    const get = useCallback(async () => {
        try {
            const resp = await SubscriptionController.find(params.idPurchased)
            if (resp) {
                setSubscription(resp.data);
            }
        } catch (err) {
            let testoToast = i18n.t("problemi_nel_caricamento")
            toast.error(testoToast)
            console.error(err);
        }
    }, []);
    useEffect(() => {
        get();
    }, [get]);
    if (!subscription) {
        return null;
    }
    return (
        <>
            <Helmet>
                <title>{i18n.t("dettaglio_acquisto_abbonamento")} | Micromega</title>
            </Helmet>
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    minHeight: '100%',
                    py: 8
                }}
            >
                <Container maxWidth={settings.compact ? 'xl' : false}>
                    <Grid
                        container
                        justifyContent="space-between"
                        spacing={3}
                    >
                        <Grid item>
                            <Typography
                                color="textPrimary"
                                variant="h5"
                            >
                                {i18n.t("dettaglio_acquisto_abbonamento")}
                            </Typography>
                            <Breadcrumbs
                                aria-label="breadcrumb"
                                separator={<ChevronRightIcon fontSize="small" />}
                                sx={{ mt: 1 }}
                            >
                                <Link
                                    color="textPrimary"
                                    component={RouterLink}
                                    to="/dashboard"
                                    variant="subtitle2"
                                >
                                    {i18n.t("dashboard")}
                                </Link>
                                <Link
                                    color="textPrimary"
                                    component={RouterLink}
                                    to="/dashboard/subscriptions/purchased"
                                    variant="subtitle2"
                                >
                                    {i18n.t("dettaglio_acquisto_abbonamento")}
                                </Link>
                            </Breadcrumbs>
                        </Grid>
                        <Grid item>
                            <Box sx={{ m: -1 }}>
                                <Button
                                    color="primary"
                                    startIcon={subscription.status ===  1 ? <RemoveCircleOutlineIcon fontSize={"small"}/> : <DoneIcon fontSize={"small"}/>}
                                    sx={{ m: 1 }}
                                    onClick={async () => {
                                        subscription.status = subscription.status === 1 ? 2 : 1
                                        try{
                                            const resp = await SubscriptionController.update(subscription)
                                            setSubscription(resp.data)
                                        }catch(ex){
                                            let testoToast = i18n.t("operazione_non_riuscita_riprova")
                                            toast.error(testoToast)
                                        }
                                    }}
                                    variant="contained">{subscription.status ===  1 ? i18n.t("blocca") : i18n.t("attiva")}</Button>
                                <Button
                                    color="primary"
                                    startIcon={<ClearIcon fontSize={"small"}/>}
                                    sx={{ m: 1 }}
                                    onClick={async () => {
                                        try{
                                            await SubscriptionController.delete(new SubscriptionDeleteDTO(subscription.id))
                                            navigate("/dashboard/subscriptions/purchased")
                                        }catch(ex){
                                            let testoToast = i18n.t("operazione_non_riuscita_riprova")
                                            toast.error(testoToast)
                                        }
                                    }}
                                    variant="contained">{i18n.t("elimina")}</Button>
                            </Box>
                        </Grid>
                    </Grid>
                    <Box sx={{ mt: 3 }}>
                        <Grid
                            container
                            spacing={3}
                            {...props}
                        >
                            <Grid
                                item
                                lg={6}
                                md={6}
                                xl={6}
                                xs={12}>
                                <SubscriptionsPurchasedSummary subscription={subscription} />
                            </Grid>
                            <Grid
                                item
                                lg={6}
                                md={6}
                                xl={6}
                                xs={12}
                            >
                                <CustomerSummary user={subscription.user} />
                            </Grid>

                        </Grid>
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export default SubscriptionPurchasedDetail;
