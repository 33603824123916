import type { FC } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import {
  Card,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core';
import i18n from "i18next";
import {User, UserStatus} from "../../../model/types/user";
import Label from "../../Label";
import {DateUtils} from "../../../utils/dateUtils";

interface CustomerSummaryProps {
  user: User;
}

const CustomerSummary: FC<CustomerSummaryProps> = (props) => {
  const { user, ...other } = props;

  const getStatusLabel = (status: UserStatus): JSX.Element => {
    const map = {
      D: {
        color: 'error',
        text: i18n.t("cancellato")
      },
      A: {
        color: 'success',
        text: i18n.t("attivo")
      },
      S: {
        color: 'warning',
        text: i18n.t("sospeso")
      },
      N: {
        color: 'warning',
        text: i18n.t("in_attivazione")
      }
    };
    const { text, color }: any = map[status];
    return (
        <Label color={color}>
          {text}
        </Label>
    );
  };
  return (
    <Card {...other}>
      <Divider />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>
              <Typography
                  color="textPrimary"
                  variant="subtitle2"
              >
                {i18n.t("id")}
              </Typography>
            </TableCell>
            <TableCell>
              #
              {user.id}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography
                  color="textPrimary"
                  variant="subtitle2"
              >
                {i18n.t("registratoInData")}
              </Typography>
            </TableCell>
            <TableCell>
              { format(DateUtils.parsedDate(user.createdAt), 'dd/MM/yyyy HH:mm')}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography
                  color="textPrimary"
                  variant="subtitle2"
              >
                {i18n.t("stato_utente")}
              </Typography>
            </TableCell>
            <TableCell>
              {getStatusLabel(user.status)}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>
              <Typography
                  color="textPrimary"
                  variant="subtitle2"
              >
                {i18n.t("nomeCognome")}
              </Typography>
            </TableCell>
            <TableCell>
              { user.nome + " "+user.cognome}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography
                  color="textPrimary"
                  variant="subtitle2"
              >
                {i18n.t("email")}
              </Typography>
            </TableCell>
            <TableCell>
              { user.email}
            </TableCell>
          </TableRow>
          {
            user.sesso && user.sesso.length > 0?
                (<TableRow>
                  <TableCell>
                    <Typography
                        color="textPrimary"
                        variant="subtitle2"
                    >
                      {i18n.t("sesso")}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    { user.sesso}
                  </TableCell>
                </TableRow>) : null
          }

          {
            user.dataNascita && user.dataNascita.length > 0?
                (      <TableRow>
                  <TableCell>
                    <Typography
                        color="textPrimary"
                        variant="subtitle2"
                    >
                      {i18n.t("data_nascita")}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    { format(DateUtils.parsedDate(user.dataNascita), 'dd/MM/yyyy')}
                  </TableCell>
                </TableRow>) : null
          }
          <TableRow>
            <TableCell>
              <Typography
                  color="textPrimary"
                  variant="subtitle2"
              >
                {i18n.t("is_azienda")}
              </Typography>
            </TableCell>
            <TableCell>
              {user.company ? i18n.t("si") : i18n.t("no")}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography
                  color="textPrimary"
                  variant="subtitle2"
              >
                {i18n.t("codiceFiscalePIVA")}
              </Typography>
            </TableCell>
            <TableCell>
              {user.codiceFiscalePIVA}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography
                  color="textPrimary"
                  variant="subtitle2"
              >
                {i18n.t("ragioneSociale")}
              </Typography>
            </TableCell>
            <TableCell>
              {user.ragioneSociale}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography
                  color="textPrimary"
                  variant="subtitle2"
              >
                {i18n.t("pec")}
              </Typography>
            </TableCell>
            <TableCell>
              {user.pec}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography
                  color="textPrimary"
                  variant="subtitle2"
              >
                {i18n.t("codiceDest")}
              </Typography>
            </TableCell>
            <TableCell>
              {user.codiceDest}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography
                  color="textPrimary"
                  variant="subtitle2"
              >
                {i18n.t("indirizzo_fatturazione")}
              </Typography>
            </TableCell>
            <TableCell>
              <div>
                {user.ragioneSociale}
              </div>
              <div>
                {user.indirizzo}
              </div>
              <div>
                {user.citta+" ("+user.provincia+")" + " "+user.cap}
              </div>
              <div>
                {user.stato}
              </div>
              <div>
                {user.telefono}
              </div>
              <div>
                {user.cellulare}
              </div>
              <div>
                {user.codiceDest}
              </div>
              <div>
                {user.pec}
              </div>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

    </Card>
  );
};

CustomerSummary.propTypes = {
  // @ts-ignore
  user: PropTypes.object.isRequired
};

export default CustomerSummary;
