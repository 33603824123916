import i18n from "i18next";
import {Subscription, SubscriptionStatus} from "./subscription";
import {State} from "./state";

export class User {
  id: number;
  avatar: string;
  email: string;
  nome: string;
  cognome: string;
  sesso: string;
  dataNascita: string;
  cellulare: string;
  company: boolean;
  codiceFiscalePIVA: string;
  ragioneSociale: string;
  indirizzo: string;
  cap: string;
  citta: string;
  provincia: string;
  stato: string;
  codiceDest: string;
  pec: string;
  telefono: string;
  status: UserStatus;
  createdAt: string;
  updatedAt: string;
  userType: UserType;
  subscriptionActive: Subscription
  newsletter: number //MAILING LIST

  public static getGenders(): Array<{value: string,label: string}>{
    return [
      {
        value: 'maschio',
        label: i18n.t("maschio")
      },
      {
        value: 'femmina',
        label: i18n.t("femmina")
      },
      {
        value: 'altro',
        label: i18n.t("altro")
      },
    ]
  }
  public static isAdmin(user: User){
    return user && user.userType.desc === UserTypeDesc.Admin
  }
  public static getIndirizzoSpedizione(user: User): string{
    let indirizzo = "";
    if(user.nome && user.cognome){
      indirizzo += user.nome+" "+user.cognome;
    }
    if(user.ragioneSociale){
      indirizzo += "\n"+user.ragioneSociale
    }
    if(user.indirizzo){
      indirizzo += "\n"+user.indirizzo
    }
    if(user.citta){
      indirizzo += " "+user.citta
    }
    if(user.provincia){
      indirizzo += " "+user.provincia
    }
    if(user.provincia){
      indirizzo += " "+user.cap
    }
    if(user.stato){
      let stateObj = State.getListState(true).filter(state => state.key === user.stato)[0]
      if(stateObj){
        indirizzo += "\n"+stateObj?.name
      }
    }
    if(user.telefono){
      indirizzo += "\n"+user.telefono
    }
    if(user.cellulare){
      indirizzo += "\n"+user.cellulare
    }
    return indirizzo;
  }
}
export enum UserStatus{
  D = "D",
  N = "N",
  A = "A",
  S = "S"
}
export interface UserType {
  id: number
  desc: UserTypeDesc
}
export enum UserTypeDesc{
  Customer = ("Customer"),
  Admin = ("Admin")
}