import { useState } from 'react';
import type { ChangeEvent, FC, MouseEvent } from 'react';
import {useNavigate} from 'react-router-dom';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardHeader,
  Divider, IconButton, InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow, TextField,
  Typography
} from '@material-ui/core';
import {Subscription, SubscriptionStatus} from "../../../../model/types/subscription";
import i18n from "i18next";
import useAuth from "../../../../hooks/useAuth";
import Label from "../../../Label";
import Scrollbar from "../../../Scrollbar";
import {format} from "date-fns";
import {Order} from "../../../../model/types/order";
import SearchIcon from "../../../../res/icons/Search";
import {DateUtils} from "../../../../utils/dateUtils";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import itLocale from "date-fns/locale/it";
import {MobileDatePicker} from "@material-ui/lab";
import {Clear} from "@material-ui/icons";

interface SubscriptionsPurchasedListTableProps {
  subscriptions: Subscription[];
}

const getStatusLabel = (status: SubscriptionStatus): JSX.Element => {
  const map = {
    0: {
      color: 'error',
      text: i18n.t("cancellato")
    },
    1: {
      color: 'success',
      text: i18n.t("attivo")
    },
    2: {
      color: 'warning',
      text: i18n.t("in_attesa")
    }
  };
  const { text, color }: any = map[status];
  return (
    <Label color={color}>
      {text}
    </Label>
  );
};

const applyPagination = (
  orders: Subscription[],
  page: number,
  limit: number
): Subscription[] => {
  return orders.slice(page * limit, page * limit + limit);
}

const applyFilters = (
    subscriptions: Subscription[],
    query: string,
    dateStart: Date,
    dateEnd: Date
): Subscription[] => subscriptions
    .filter((subscription) => {
      let matches = true;
      if (query) {
        const properties = ['transactionId', 'id', 'method','subscriptionType'];
        let containsQuery = false;
        properties.forEach((property) => {
          if (String(JSON.stringify(subscription[property])).replaceAll(/\s/g,'').toLowerCase().includes(query.replaceAll(/\s/g,'').toLowerCase())) {
            containsQuery = true;
          }
          if(!containsQuery && subscription.user){
              let userNomeCognome = subscription.user.nome+" "+subscription.user.cognome
              if(userNomeCognome.toLowerCase().replaceAll(/\s/g,'').includes(query.replaceAll(/\s/g,'').toLowerCase())){
                  containsQuery = true;
              }
          }
        });
        if (!containsQuery) {
          matches = false;
        }
      }
      if(matches && dateStart && dateEnd && dateStart < dateEnd){
        let createdDate = DateUtils.parsedDate(subscription.createdAt)
        let containsQuery = createdDate && createdDate >= dateStart && createdDate <= dateEnd;
        if (!containsQuery) {
          matches = false;
        }
      }
      return matches;
    });
const SubscriptionsPurchasedList: FC<SubscriptionsPurchasedListTableProps> = (props) => {
  const {user} = useAuth()
  const navigate = useNavigate()
  const { subscriptions, ...other } = props;
  const dateStartSubscriptionsPurchased = localStorage.getItem("dateStartSubscriptionsPurchased");
  const [dateStart, setDateStart] = useState<Date>(dateStartSubscriptionsPurchased && dateStartSubscriptionsPurchased != "null" ? new Date(dateStartSubscriptionsPurchased) : null);
  const dateEndSubscriptionsPurchased = localStorage.getItem("dateEndSubscriptionsPurchased");
  const [dateEnd, setDateEnd] = useState<Date>(dateEndSubscriptionsPurchased  && dateEndSubscriptionsPurchased != "null" ? new Date(dateEndSubscriptionsPurchased) : null);
  const pageSubscriptionsPurchased = localStorage.getItem("pageSubscriptionsPurchased");
  const [page, setPage] = useState<number>(pageSubscriptionsPurchased ? Number(pageSubscriptionsPurchased) : 0);
  const querySubscriptionsPurchased = localStorage.getItem("querySubscriptionsPurchased");
  const [query, setQuery] = useState<string>(querySubscriptionsPurchased ? querySubscriptionsPurchased : '');
  const limitSubscriptionsPurchased = localStorage.getItem("limitSubscriptionsPurchased");
  const [limit, setLimit] = useState<number>(limitSubscriptionsPurchased ? Number(limitSubscriptionsPurchased) : 50);
  const handlePageChange = (event: MouseEvent<HTMLButtonElement> | null, newPage: number): void => {
    setPage(newPage);
    localStorage.setItem("pageSubscriptionsPurchased",String(newPage))
  };
  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value, 10));
    localStorage.setItem("limitSubscriptionsPurchased",String(parseInt(event.target.value, 10)))
  };
  const handleQueryChange = (event: ChangeEvent<HTMLInputElement>): void => {
      setPage(0)
      localStorage.setItem("pageSubscriptionsPurchased",String(0))
      setQuery(event.target.value);
    localStorage.setItem("querySubscriptionsPurchased",event.target.value)
  };
  const filteredSubscription = applyFilters(subscriptions, query,dateStart,dateEnd);
  const paginatedSubscription = applyPagination(filteredSubscription, page, limit);
  return (
    <>
      <Card {...other}>
        <CardHeader
          title={i18n.t("abbonamenti_acquistati")}
        />
        <Divider />
        <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexWrap: 'wrap',
              m: -1,
              p: 2
            }}
        >
          <Box
              sx={{
                m: 1,
                maxWidth: '100%',
                display: 'flex',
                flexDirection: 'row'
              }}
          >
            <TextField
                fullWidth
                sx={{minWidth: 300}}
                InputProps={{
                  startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon fontSize="small" />
                      </InputAdornment>
                  )
                }}
                onChange={handleQueryChange}
                placeholder={i18n.t("cerca_abbonamenti")}
                value={query}
                variant="outlined"
            />
            <Box sx={{ml: 2, minWidth: 300}}>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={itLocale}>
                <MobileDatePicker
                    label={i18n.t("data_inizio")}
                    onChange={date => {
                        setPage(0)
                        localStorage.setItem("pageSubscriptionsPurchased",String(0))
                      setDateStart(date)
                      localStorage.setItem("dateStartSubscriptionsPurchased",date.toString())
                    }}
                    renderInput={(inputProps) => (
                        <TextField
                            fullWidth
                            name="data_inizio"
                            variant="outlined"
                            {...inputProps}
                        />
                    )}
                    InputProps={{
                      endAdornment: dateStart ? ((
                          <IconButton onClick={(e) => {
                              setPage(0)
                              localStorage.setItem("pageSubscriptionsPurchased",String(0))
                            setDateStart(null)
                            localStorage.setItem("dateStartSubscriptionsPurchased",null)

                          }}>
                            <Clear />
                          </IconButton>
                      )) : null
                    }}
                    value={dateStart}
                />
              </LocalizationProvider>
            </Box>
            <Box sx={{ml: 2, minWidth: 300}}>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={itLocale}>
                <MobileDatePicker
                    label={i18n.t("data_fine")}
                    onChange={date => {
                        setPage(0)
                        localStorage.setItem("pageSubscriptionsPurchased",String(0))
                      setDateEnd( date)
                      localStorage.setItem("dateEndSubscriptionsPurchased",date.toString())
                    }}
                    renderInput={(inputProps) => (
                        <TextField
                            fullWidth
                            name="data_fine"
                            variant="outlined"
                            {...inputProps}
                        />
                    )}
                    InputProps={{
                      endAdornment: dateEnd ? ((
                          <IconButton onClick={(e) => {
                              setPage(0)
                              localStorage.setItem("pageSubscriptionsPurchased",String(0))
                            setDateEnd(null)
                            localStorage.setItem("dateEndSubscriptionsPurchased",null)
                          }}>
                            <Clear />
                          </IconButton>
                      )) : null
                    }}
                    value={dateEnd}
                />
              </LocalizationProvider>
            </Box>
          </Box>
        </Box>
        <Scrollbar>
          <Box sx={{ minWidth: 1150 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    {i18n.t("numero")}
                  </TableCell>
                  <TableCell>
                    {i18n.t("idTransaction")}
                  </TableCell>
                  <TableCell>
                    {i18n.t("data")}
                  </TableCell>
                  <TableCell>
                    {i18n.t("utente")}
                  </TableCell>
                  <TableCell>
                    {i18n.t("tipologia")}
                  </TableCell>
                  <TableCell>
                    {i18n.t("metodo")}
                  </TableCell>
                  <TableCell>
                    {i18n.t("totale")}
                  </TableCell>
                  <TableCell>
                    {i18n.t("stato")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedSubscription.map((subscription) => {
                  return (
                    <TableRow
                      hover
                      key={subscription.id}
                      sx={{cursor:'pointer'}}
                      onClick={() =>{navigate(`/dashboard/subscriptions/purchased/${subscription.id}`);}}
                    >
                      <TableCell>
                        <Typography
                            color="textSecondary"
                            variant="body2"
                        >
                          {"#"+subscription.id}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                            color="textSecondary"
                            variant="body2"
                        >
                          {subscription.transactionId}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                            color="textSecondary"
                            variant="body2"
                        >
                          {format(DateUtils.parsedDate(subscription.createdAt), 'dd MMM yyyy | HH:mm')}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        {subscription.user.nome + " "+subscription.user.cognome}
                      </TableCell>
                      <TableCell>
                        {subscription.subscriptionType.description}
                      </TableCell>
                      <TableCell>
                        {i18n.t(subscription.method)}
                      </TableCell>
                      <TableCell>
                        {numeral(subscription.amount).format(`0,0.00`)+" "+i18n.t("currency_symbol")}
                      </TableCell>
                      <TableCell>
                        {getStatusLabel(subscription.status)}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
        </Scrollbar>
        <TablePagination
          component="div"
          count={paginatedSubscription.length < limit ? paginatedSubscription.length : subscriptions.length}
          onPageChange={handlePageChange}
          labelDisplayedRows={
            ({ from, to, count }) => {
              return '' + from + '-' + to + ' '+i18n.t("di")+' ' + count
            }
          }
          labelRowsPerPage={i18n.t("righe_per_pagine")}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[50, 100, 10000]}
        />
      </Card>
    </>
  );
};

SubscriptionsPurchasedList.propTypes = {
  subscriptions: PropTypes.array.isRequired
};

export default SubscriptionsPurchasedList;
