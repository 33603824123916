import type {FC} from 'react';
import {useEffect} from 'react';
import {Link as RouterLink, useLocation, useNavigate} from 'react-router-dom';
import PropTypes from 'prop-types';
import type {Theme} from '@material-ui/core';
import {Avatar, Box, Divider, Drawer, Typography} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useAuth from '../../hooks/useAuth';
import UsersIcon from '../../res/icons/Users';
import Logo from '../Logo';
import Scrollbar from '../Scrollbar';
import i18n from "i18next";
import {UserTypeDesc} from "../../model/types/user";
import NavSection from "../NavSection";
import PersonIcon from '@material-ui/icons/Person';
import HomeIcon from '@material-ui/icons/Home';
import SubscriptionsIcon from '@material-ui/icons/CardMembership';
import HelpIcon from '@material-ui/icons/Help';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import BallotIcon from '@material-ui/icons/Ballot';
import {CardGiftcard} from "@material-ui/icons";
import AppsIcon from '@material-ui/icons/Apps';
import getInitials from "../../utils/getInitials";
interface DashboardSidebarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}
const section_offline = [
    {
        title: "",
        items: [
            {
                title: i18n.t("overview"),
                path: '/dashboard',
                icon: <HomeIcon fontSize="small" />
            },
            {
                title: i18n.t("acquisti"),
                path: '/dashboard/products',
                icon: <LibraryBooksIcon fontSize="small" />
            },
            {
                title: i18n.t("negozio"),
                path: '/dashboard/subscriptions',
                icon: <SubscriptionsIcon fontSize="small" />
            },
            {
                title: i18n.t("contact"),
                path: '/contact',
                icon: <HelpIcon fontSize="small" />
            },
        ]
    }
];
const sections_user = [
  {
    title: "",
    items: [
        {
            title: i18n.t("overview"),
            path: '/dashboard',
            icon: <HomeIcon fontSize="small" />
        },
        {
            title: i18n.t("profile"),
            path: '/dashboard/profile',
            icon: <PersonIcon fontSize="small" />
        },
        {
            title: i18n.t("i_miei_acquisti"),
            path: '/dashboard/products',
            icon: <LibraryBooksIcon fontSize="small" />
        },
        {
            title: i18n.t("negozio"),
            path: '/dashboard/subscriptions',
            icon: <SubscriptionsIcon fontSize="small" />
        },
        {
            title: i18n.t("contact"),
            path: '/contact',
            icon: <HelpIcon fontSize="small" />
        },
    ]
  }
];
const sections_admin = [
    {
        title: "",
        items: [
            {
                title: i18n.t("overview"),
                path: '/dashboard',
                icon: <HomeIcon fontSize="small" />
            },
            {
                title: i18n.t("profile"),
                path: '/dashboard/profile',
                icon: <PersonIcon fontSize="small" />
            },
            {
                title: i18n.t("prodotti"),
                path: '/dashboard/products',
                icon: <LibraryBooksIcon fontSize="small" />
            },
            {
                title: i18n.t("tipologie_abbonamenti"),
                path: '/dashboard/subscriptions',
                icon: <AppsIcon fontSize="small" />
            },
            {
                title: i18n.t("coupon"),
                path: '/dashboard/coupons',
                icon: <CardGiftcard fontSize="small" />
            },
            {
                title: i18n.t("abbonamenti"),
                path: '/dashboard/subscriptions/purchased',
                icon: <SubscriptionsIcon fontSize="small" />
            },
            {
                title: i18n.t("ordini"),
                path: '/dashboard/orders',
                icon: <BallotIcon fontSize="small" />
            },
            {
                title: i18n.t("utenti"),
                path: '/dashboard/customers',
                icon: <UsersIcon fontSize="small" />
            },
        ]
    }
];
const DashboardSidebar: FC<DashboardSidebarProps> = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const { user } = useAuth();
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const navigate = useNavigate()
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box
          sx={{
            display: {
              lg: 'none',
              xs: 'flex'
            },
            justifyContent: 'center',
            p: 2
          }}
        >
          <RouterLink to="/">
            <Logo
              sx={{
                height: 40,
                width: 40
              }}
            />
          </RouterLink>
        </Box>
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              alignItems: 'center',
              backgroundColor: 'background.default',
              borderRadius: 1,
              display: 'flex',
              overflow: 'hidden',
              p: 2
            }}
          >
              {
                  user ? (
                          <><Avatar
                              src={user.avatar}
                              sx={{
                                  cursor: 'pointer',
                                  height: 48,
                                  width: 48
                              }}
                              onClick={()=>{navigate("/dashboard/profile")}}
                          >
                              {getInitials(user.nome+" "+user.cognome)}
                          </Avatar>
                      <Box sx={{ ml: 2 }}>
                          <Typography
                              color="textPrimary"
                              variant="subtitle2"
                          >
                              {user.nome + " " +user.cognome}
                          </Typography>
                      </Box></>
                  ):(
                      <>
                          <Avatar
                              src={null}
                              sx={{
                                  cursor: 'pointer',
                                  height: 48,
                                  width: 48
                              }}
                          >
                              {getInitials(i18n.t("guest"))}
                          </Avatar>
                          <Box sx={{ ml: 2 }}>
                              <Typography
                                  color="textPrimary"
                                  variant="subtitle2"
                              >
                                  {i18n.t("guest")}
                              </Typography>
                          </Box>
                      </>
                  )
              }
          </Box>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          {
              !user ? (
                  section_offline.map((section) => (
                      <NavSection
                          key={section.title}
                          pathname={location.pathname}
                          sx={{
                              '& + &': {
                                  mt: 3
                              }
                          }}
                          {...section}
                      />
                  ))
                  ) : user.userType.desc == UserTypeDesc.Customer ? (
                  sections_user.map((section) => (
                      <NavSection
                          key={section.title}
                          pathname={location.pathname}
                          sx={{
                              '& + &': {
                                  mt: 3
                              }
                          }}
                          {...section}
                      />
                  ))
              ): user.userType.desc == UserTypeDesc.Admin ? (
                  sections_admin.map((section) => (
                      <NavSection
                          key={section.title}
                          pathname={location.pathname}
                          sx={{
                              '& + &': {
                                  mt: 3
                              }
                          }}
                          {...section}
                      />
                  ))
              ): null
            }
        </Box>
      </Scrollbar>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'background.paper',
            height: 'calc(100% - 64px) !important',
            top: '64px !Important',
            width: 280
          }
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: 'background.paper',
          width: 280
        }
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default DashboardSidebar;
