import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { AppBar, Box, Button, Chip, Divider, IconButton, Link, Toolbar } from '@material-ui/core';
import MenuIcon from '../res/icons/Menu';
import Logo from './Logo';
import i18n from "i18next";
import AccountPopover from "./dashboard/AccountPopover";
import useAuth from "../hooks/useAuth";

interface MainNavbarProps {
  onSidebarMobileOpen?: () => void;
}

const MainNavbar: FC<MainNavbarProps> = (props) => {
  const { onSidebarMobileOpen } = props;
  const { user } = useAuth();

  return (
    <AppBar
      elevation={0}
      sx={{
        backgroundColor: 'background.paper',
        color: 'text.secondary'
      }}
    >
      <Toolbar sx={{ minHeight: 64 }}>
        <IconButton
          color="inherit"
          onClick={onSidebarMobileOpen}
          sx={{
            display: {
              md: 'none'
            }
          }}
        >
          <MenuIcon fontSize="small" />
        </IconButton>
        <RouterLink to="/">
          <Logo
            sx={{
              display: {
                md: 'inline',
                xs: 'none'
              },
              height: 40,
              width: 40
            }}
          />
        </RouterLink>
        <Box sx={{ flexGrow: 1 }} />
        <Box
          sx={{
            alignItems: 'center',
            display: {
              md: 'flex',
              xs: 'none'
            }
          }}
        >
            {
                user ? (
                    <Box sx={{ ml: 2 }}>
                        <AccountPopover />
                    </Box>
                ) : (
                    <><Button
                        color="secondary"
                        component="a"
                        href="/authentication/register"
                        size="small"
                        variant="contained">
                        {i18n.t("registrati")}
                    </Button>
                        <Divider
                            orientation="vertical"
                            sx={{height: 32, mx: 2}}
                        />
                        <Button
                            color="primary"
                            component="a"
                            href="/authentication/login"
                            size="small"
                            variant="contained">
                            {i18n.t("accedi")}
                        </Button></>
                )
            }

        </Box>
      </Toolbar>
      <Divider />
    </AppBar>
  );
};

MainNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func
};

export default MainNavbar;
