import type {FC} from 'react';
import {useCallback, useEffect, useState} from 'react';
import {Link as RouterLink, useNavigate, useParams} from 'react-router-dom';
import {Helmet} from 'react-helmet-async';
import {
    Box,
    Breadcrumbs,
    Button,
    Card,
    CardHeader, Checkbox,
    Container,
    Divider, FormHelperText,
    Grid,
    Link, TextField,
    Typography
} from '@material-ui/core';
import ChevronRightIcon from '../../../res/icons/ChevronRight';
import useSettings from '../../../hooks/useSettings';
import i18n from "i18next";
import toast from "react-hot-toast";
import * as Yup from "yup";
import {Formik} from "formik";
import Label from "../../../components/Label";
import {CouponController} from "../../../api/controller/coupon.controller";
import {Coupon, CouponStatus} from "../../../model/types/coupon";
import ClearIcon from '@material-ui/icons/Clear';
import DoneIcon from '@material-ui/icons/Done';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';

const CouponDetail: FC = (props) => {
  const { settings } = useSettings();
  const [coupon, setCoupon] = useState<Coupon | null>(null);
  const params = useParams();
  const navigate = useNavigate()
    const getStatusLabel = (status: number): JSX.Element => {
        const map = {
            0: {
                color: 'warning',
                text: i18n.t("sospeso")
            },
            1: {
                color: 'success',
                text: i18n.t("attivo")
            }
        };
        const { text, color }: any = map[status];
        return (
            <Label color={color}>
                {text}
            </Label>
        );
    };
    const get = useCallback(async () => {
    try {
      const id = params.couponId
      const resp = await CouponController.find(id)
      if (resp) {
        setCoupon(resp.data);
      }
    } catch (err) {
      let testoToast = i18n.t("problemi_nel_caricamento")
      toast.error(testoToast)
      console.error(err);
    }
  }, []);
  useEffect(() => {
    get();
  }, [get]);
  if (!coupon) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>{i18n.t("coupon_detail")} | Micromega</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
                <Typography
                    color="textPrimary"
                    variant="h5"
                >
                    {i18n.t("coupon") +": " + coupon.code}
                </Typography>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={<ChevronRightIcon fontSize="small" />}
                sx={{ mt: 1 }}
              >
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/dashboard"
                  variant="subtitle2"
                >
                  {i18n.t("dashboard")}
                </Link>
                <Link
                  color="textPrimary"
                  component={RouterLink}
                  to="/dashboard/coupons"
                  variant="subtitle2"
                >
                    {i18n.t("coupon")}
                </Link>
              </Breadcrumbs>
            </Grid>
            <Grid item>
              <Box sx={{ m: -1 }}>
                <Button
                    color="primary"
                    startIcon={coupon.status ? <RemoveCircleOutlineIcon fontSize="small" /> :  <DoneIcon fontSize="small" /> }
                    sx={{ m: 1 }}
                    onClick={async () => {
                          coupon.status = coupon.status === CouponStatus.Enabled ? CouponStatus.Disabled : CouponStatus.Enabled
                        try{
                        const resp = await CouponController.update(coupon)
                        setCoupon(resp.data)
                      }catch(ex){
                        let testoToast = i18n.t("operazione_non_riuscita_riprova")
                        toast.error(testoToast)
                      }
                    }}
                    variant="contained">{coupon.status ? i18n.t("sospendi") : i18n.t("attiva")}</Button>
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
              <Grid
                  container
                  spacing={3}
                  {...props}
              >
                  <Grid
                      item
                      lg={6}
                      md={6}
                      xl={6}
                      xs={12}>
                      <Formik
                          initialValues={{
                              amount: coupon.amount,
                              code: coupon.code,
                              percentage: coupon.percentage,
                              submit: null
                          }}
                          validationSchema={
                              Yup
                                  .object()
                                  .shape({
                                      amount: Yup
                                          .number()
                                          .min(0)
                                          .required(i18n.t("required_amount")),
                                      code: Yup
                                          .string()
                                          .required(i18n.t("required_code")),
                                      percentage: Yup
                                          .boolean()
                                          .required(i18n.t("required_percentage"))
                                  })
                          }
                          onSubmit={async (values, {
                              setErrors,
                              setStatus,
                              setSubmitting
                          }): Promise<void> => {
                              try {
                                  coupon.code = values.code
                                  coupon.amount = values.amount
                                  coupon.percentage = values.percentage
                                  const resp = await CouponController.update(coupon)
                                  if (resp) {
                                      setStatus({ success: true });
                                      setSubmitting(false);
                                      let tostMsg = i18n.t("aggiornato");
                                      toast.success(tostMsg);
                                      navigate('/dashboard/coupons/'+resp.data.id);
                                  }
                              } catch (err) {
                                  console.error(err);
                                  setStatus({ success: false });
                                  let toastMsg = i18n.t("problemi_elaborazione")
                                  toast.error(toastMsg);
                                  setErrors({ submit: err.stack.msg ? err.stack.msg : err.message})
                                  setSubmitting(false);
                              }
                          }}>
                          {({
                                errors,
                                handleBlur,
                                handleChange,
                                handleSubmit,
                                isSubmitting,
                                touched,
                                values,
                                setFieldValue
                            }): JSX.Element => (
                              <form
                                  noValidate
                                  onSubmit={handleSubmit}
                                  {...props}
                              >
                                  <Card>
                                      <CardHeader title={i18n.t("modifica_coupon")} />
                                      <Divider />
                                      <Grid
                                          container
                                          sx={{p: 2}}
                                          spacing={4}>
                                          <Grid
                                              item
                                              md={12}
                                              xs={12}>
                                              <TextField
                                                  error={Boolean(touched.code && errors.code)}
                                                  fullWidth
                                                  helperText={touched.code && errors.code}
                                                  label={i18n.t("codice")}
                                                  name="code"
                                                  onBlur={handleBlur}
                                                  onChange={handleChange}
                                                  value={values.code}
                                                  variant="outlined"
                                              />
                                          </Grid>
                                          <Grid
                                              item
                                              md={12}
                                              xs={12}>
                                              <TextField
                                                  error={Boolean(touched.amount && errors.amount)}
                                                  fullWidth
                                                  helperText={touched.amount && errors.amount}
                                                  label={i18n.t("importo")}
                                                  name="amount"
                                                  onBlur={handleBlur}
                                                  onChange={handleChange}
                                                  value={values.amount}
                                                  variant="outlined"
                                              />
                                          </Grid>
                                          <Grid
                                              item
                                              md={12}
                                              xs={12}>
                                              <Box
                                                  sx={{
                                                      alignItems: 'center',
                                                      display: 'flex',
                                                      p: 1
                                                  }}>
                                                  <Typography
                                                      color="textSecondary"
                                                      variant="body2">{i18n.t("stato_coupon")+": \t"}</Typography>
                                                  <Box sx={{ml: 1}}>{getStatusLabel(coupon.status)}</Box>
                                              </Box>
                                              <Box
                                                  sx={{
                                                      alignItems: 'center',
                                                      display: 'flex',
                                                  }}
                                              >
                                                  <Checkbox
                                                      checked={Boolean(values.percentage)}
                                                      color="primary"
                                                      name="percentage"
                                                      onChange={handleChange}
                                                  />
                                                  <Typography
                                                      color="textSecondary"
                                                      variant="body2"
                                                  >
                                                      {i18n.t("percentuale")}
                                                  </Typography>
                                              </Box>
                                          </Grid>
                                          <Grid item
                                                md={12}
                                                xs={12}>
                                              {errors.submit && (
                                                  <Box sx={{ mt: 3 }}>
                                                      <FormHelperText error>
                                                          {errors.submit}
                                                      </FormHelperText>
                                                  </Box>
                                              )}
                                              <Box sx={{ mt: 2 }}>
                                                  <Button
                                                      color="primary"
                                                      disabled={isSubmitting}
                                                      size="large"
                                                      type="submit"
                                                      variant="contained"
                                                  >
                                                      {i18n.t("aggiorna")}
                                                  </Button>
                                              </Box>
                                          </Grid>

                                      </Grid>
                                  </Card>

                              </form>
                          )}
                      </Formik>
                  </Grid>
                  <Grid
                      item
                      lg={6}
                      md={6}
                      xl={6}
                      xs={12}
                  >
                      {
                          /*
                           <Card>
                          <CardContent>
                              <Box
                                  sx={{
                                      alignItems: 'center',
                                      display: 'flex',
                                      flexDirection: 'column',
                                      textAlign: 'center'
                                  }}
                              >

                              </Box>
                          </CardContent>
                      </Card>
                           */
                      }
                  </Grid>

              </Grid>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default CouponDetail;
