import PropTypes from 'prop-types';
import { format } from 'date-fns';
import numeral from 'numeral';
import {
  Card,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from '@material-ui/core';
import i18n from "i18next";
import {Subscription, SubscriptionStatus} from "../../../../model/types/subscription";
import Label from "../../../Label";
import {FC} from "react";
import {DateUtils} from "../../../../utils/dateUtils";

interface SubscriptionsPurchasedSummaryProps {
  subscription: Subscription;
}

const SubscriptionsPurchasedSummary: FC<SubscriptionsPurchasedSummaryProps> = (props) => {
  const { subscription, ...other } = props;

  const getStatusLabel = (status: SubscriptionStatus): JSX.Element => {
    const map = {
      1: {
        color: 'success',
        text: i18n.t("attivo")
      },
      2: {
        color: 'warning',
        text: i18n.t("in_attesa")
      },
      3: {
        color: 'error',
        text: i18n.t("cancellato")
      },
    };
    const { text, color }: any = map[status];
    return (
        <Label color={color}>
          {text}
        </Label>
    );
  };
  return (
    <Card {...other}>
      <Divider />
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>
              <Typography
                  color="textPrimary"
                  variant="subtitle2"
              >
                {i18n.t("id")}
              </Typography>
            </TableCell>
            <TableCell>
              #
              {subscription.id}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography
                  color="textPrimary"
                  variant="subtitle2"
              >
                {i18n.t("idTransaction")}
              </Typography>
            </TableCell>
            <TableCell>
              {subscription.transactionId}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography
                  color="textPrimary"
                  variant="subtitle2"
              >
                {i18n.t("data")}
              </Typography>
            </TableCell>
            <TableCell>
              { format(DateUtils.parsedDate(subscription.createdAt), 'dd/MM/yyyy HH:mm')}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>
              <Typography
                  color="textPrimary"
                  variant="subtitle2"
              >
                {i18n.t("tipologia")}
              </Typography>
            </TableCell>
            <TableCell>
              {subscription.subscriptionType.description}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>
              <Typography
                  color="textPrimary"
                  variant="subtitle2"
              >
                {i18n.t("startDate")}
              </Typography>
            </TableCell>
            <TableCell>
              { format(DateUtils.parsedDate(subscription.startDate), 'dd/MM/yyyy HH:mm')}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography
                  color="textPrimary"
                  variant="subtitle2"
              >
                {i18n.t("stato")}
              </Typography>
            </TableCell>
            <TableCell>
              {getStatusLabel(subscription.status)}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>
              <Typography
                  color="textPrimary"
                  variant="subtitle2"
              >
                {i18n.t("metodo_di_pagamento")}
              </Typography>
            </TableCell>
            <TableCell>
              {i18n.t(subscription.method)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                {i18n.t("importo")}
              </Typography>
            </TableCell>
            <TableCell>
              {numeral(subscription.amount).format(`0,0.00`)+" "+i18n.t("currency_symbol")}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>
              <Typography
                  color="textPrimary"
                  variant="subtitle2"
              >
                {i18n.t("fattura")}
              </Typography>
            </TableCell>
            <TableCell>
              {subscription.fattura ? i18n.t("si") : i18n.t("no")}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography
                  color="textPrimary"
                  variant="subtitle2"
              >
                {i18n.t("indirizzo_di_spedizione")}
              </Typography>
            </TableCell>
            <TableCell>
              <div style={{whiteSpace: 'pre-wrap', overflowWrap: 'break-word'}}>
                <Typography
                    variant="body1">
                  {subscription.indirizzo}
                </Typography>
              </div>
            </TableCell>
          </TableRow>
          {
            subscription.coupon ? (
                <>
                  <TableRow>
                    <TableCell>
                      <Typography
                          color="textPrimary"
                          variant="subtitle2">
                        {i18n.t("coupon")}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                          color="textPrimary"
                          variant="subtitle2">
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography
                          color="textPrimary"
                          variant="subtitle2"
                      >
                        {i18n.t("codice")}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {subscription.coupon.code}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography
                          color="textPrimary"
                          variant="subtitle2"
                      >
                        {i18n.t("importo")}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {subscription.coupon.amount}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography
                          color="textPrimary"
                          variant="subtitle2"
                      >
                        {i18n.t("percentuale")}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {subscription.coupon.percentage ? i18n.t("si") : i18n.t("no")}
                    </TableCell>
                  </TableRow>
                </>
            ) : null
          }
        </TableBody>
      </Table>

    </Card>
  );
};

SubscriptionsPurchasedSummary.propTypes = {
  // @ts-ignore
  subscription: PropTypes.object.isRequired
};

export default SubscriptionsPurchasedSummary;
