export class Coupon {
  id: number;
  amount: number
  percentage: boolean
  code: string
  status: CouponStatus
  createdAt: string
}
export enum CouponStatus{
  Enabled = 1,
  Disabled = 0
}